import { Broker, LogEvent } from '@amzn/amazonmq-opsconsole-client';
import { Button, DateRangePicker, DateRangePickerProps, Header, Link, Pagination, SpaceBetween, StatusIndicator, Table, TextContent, TextFilter } from '@amzn/awsui-components-react';
import { utcFormat } from 'd3-time-format';
import React from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import LogSourceSelector from './LogSourceSelector';
import { LogEventWithSource, LogSource } from '../../../types';
import { createIsengardLink } from '../../../utils';
import useConfig from '../../../hooks/useConfig';

type Props = {
    broker: Broker,
    logSources: LogSource[],
    selectedLogSources: LogSource[],
    logEvents: LogEventWithSource[] | undefined,
    loading: boolean,
    filter: string,
    onFilterUpdated: (newFilter: string) => void,
    dateRange: DateRangePickerProps.Value,
    onDateRangeUpdated: (newDateRange: DateRangePickerProps.Value) => void,
    onLogSourcesSelected: (newLogSources: LogSource[]) => void,
    onRefresh: () => void
}

type TableItem = {
    source: string,
    timestamp: number,
    message: string
}

const LogEventsTable : React.FC<Props> = ({broker, logSources, selectedLogSources, onLogSourcesSelected, logEvents, loading, filter, onFilterUpdated, dateRange, onDateRangeUpdated, onRefresh}) => {

    const config = useConfig();

    const {
        items,
        collectionProps,
        paginationProps,
        filterProps
      } = useCollection(logEvents !== undefined ? logEvents.map(le => { return { source: le.logSource.label, timestamp: le.logEvent.timestamp, message: le.logEvent.message}}) : [], {
        sorting: {
          defaultState: {
            sortingColumn: {
                sortingField: 'timestamp'
            },
            isDescending: true,
          },
        },
        pagination: {
            pageSize: 100
        },
        filtering: {
            filteringFunction: (logEvent: TableItem) => {
                return logEvent.message.toLowerCase().includes(filter.toLowerCase())
            }
        }
    });

    let columnDefinitions = [];
    if (selectedLogSources.length > 1) {
        columnDefinitions.push({
            id: "source",
            header: "Source",
            width: 370,
            cell: (item: TableItem) => item.source
        })
    }

    columnDefinitions.push(
        {
            id: "timestamp",
            header: "Timestamp",
            sortingField: 'timestamp',
            width: 200,
            cell: (item: TableItem) => utcFormat('%B %d, %Y %H:%M')(new Date(item.timestamp))
        },
        {
            id: "message",
            header: "Message",
            cell: (item: TableItem) => item.message
        }
    );

    const relativeOptions: DateRangePickerProps.RelativeOption[] = [
        {
            key: 'previous-30-minutes',
            amount: 30,
            unit: 'minute',
            type: 'relative',
        },
        {
            key: 'previous-1-hour',
            amount: 1,
            unit: 'hour',
            type: 'relative',
        },
        {
            key: 'previous-6-hours',
            amount: 6,
            unit: 'hour',
            type: 'relative',
        },
        {
            key: 'previous-24-hours',
            amount: 24,
            unit: 'hour',
            type: 'relative',
        },
    ];

    function createCloudwatchLink(broker: Broker, logSource: LogSource): string {
        if (config === undefined) {
            return "";
        }
        return createIsengardLink(config.isengardEndpoint, broker.brokerInfo.cellAccountId, 'ReadOnly',
            `cloudwatch/home?region=${broker.regionName}#logsV2:log-groups/log-group/${encodeURIComponent(logSource.logGroupName)}/log-events/${encodeURIComponent(logSource.logStreamName)}`);
    }

    return (
        <Table
            {...collectionProps}
            items={items}
            header={
                <Header variant="h2" actions={
                    <SpaceBetween size='xs' direction='horizontal'>
                        <Button disabled={loading} iconName="refresh" onClick={() => {
                            onRefresh();
                        }} />
                    </SpaceBetween>
                }>
                    Broker Logs
                </Header>
            }
            pagination={
                <Pagination {...paginationProps} />
            }
            filter={
                <SpaceBetween direction='vertical' size='xs'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <LogSourceSelector
                            selectedLogSources={selectedLogSources}
                            onLogSourcesSelected={onLogSourcesSelected}
                            logSources={logSources}
                        />
                        <div style={{width: 500}}>
                            <TextFilter
                                {...filterProps}
                                filteringPlaceholder="Filter logs"
                                filteringText={filter}
                                onChange={(event) => {
                                    onFilterUpdated(event.detail.filteringText);
                                }}
                            />
                        </div>
                        <DateRangePicker
                            onChange={({detail}) => {
                                onDateRangeUpdated(detail.value!)
                            }
                            }
                            value={dateRange}
                            relativeOptions={relativeOptions}
                            isValidRange={(range) => {
                                if (range?.type === "absolute") {
                                    const [startDateWithoutTime] = range.startDate.split("T");
                                    const [endDateWithoutTime] = range.endDate.split("T");
                                    if (!startDateWithoutTime || !endDateWithoutTime) {
                                        return {
                                            valid: false,
                                            errorMessage:
                                                "The selected date range is incomplete. Select a start and end date for the date range.",
                                        };
                                    }
                                    if (new Date(range.startDate) > new Date(range.endDate)) {
                                        return {
                                            valid: false,
                                            errorMessage:
                                                "The selected date range is invalid. The start date must be before the end date.",
                                        };
                                    }
                                }
                                return {valid: true};
                            }}
                            i18nStrings={{
                                applyButtonLabel: "Set Time Range",
                                relativeModeTitle: "Relative mode",
                                absoluteModeTitle: "Absolute mode",
                                relativeRangeSelectionHeading: "Choose a range",
                                startDateLabel: "Start date",
                                endDateLabel: "End date",
                                startTimeLabel: "Start time",
                                endTimeLabel: "End time",
                                customRelativeRangeDurationLabel: "Duration",
                                customRelativeRangeDurationPlaceholder: "Enter duration",
                                customRelativeRangeOptionLabel: "Custom range",
                                customRelativeRangeOptionDescription: "Set a custom range in the past",
                                customRelativeRangeUnitLabel: "Unit of time",
                                formatUnit: /* istanbul ignore next */ (e, t) => (1 === t ? e : `${e}s`),
                                formatRelativeRange: /* istanbul ignore next */ (e) => {
                                    const t = 1 === e.amount ? e.unit : `${e.unit}s`;
                                    return `Last ${e.amount} ${t}`;
                                },
                            }}
                            absoluteFormat="long-localized"
                            placeholder="Filter by a date and time range"
                            timeInputFormat="hh:mm"
                            timeOffset={0}
                        />
                    
                    </SpaceBetween>
                    {selectedLogSources.length === 1 &&
                        <Link href={createCloudwatchLink(broker, selectedLogSources[0])} external={true} target='_blank'>View in CloudWatch</Link>
                    }
                </SpaceBetween>
            }
            loading={loading}
            wrapLines={true}
            empty={
                <>
                    {selectedLogSources.length === 0 && <StatusIndicator type='info'>Please select log source</StatusIndicator>}
                    {selectedLogSources.length > 0 && <TextContent>No log events found.</TextContent>}
                </>
            }
            columnDefinitions={columnDefinitions}
        />
    )
}

export default LogEventsTable;