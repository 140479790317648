import { Broker, BrokerAutoScalingGroupActivity, BrokerAutoScalingGroupInstance, DescribeBrokerAutoScalingGroupsOutput } from '@amzn/amazonmq-opsconsole-client';
import React, { useEffect, useState } from 'react';
import { getBrokerAutoScalingGroup } from '../../../api/api';
import { Button, Header, SpaceBetween, Spinner, StatusIndicator, Table, KeyValuePairs, Container, Link, Flashbar } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { isoFormat } from 'd3-time-format';
import { createIsengardLink } from '../../../utils';
import useConfig from '../../../hooks/useConfig';


type Props = {
    broker: Broker,
}

const AutoScalingGroups : React.FC<Props> = ({broker}) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [autoScalingGroupOutput, setAutoScalingGroupOutput] = useState<DescribeBrokerAutoScalingGroupsOutput | undefined>(undefined);
    const [refreshCnt, setRefreshCnt] = useState<number>(0);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        setLoading(true);
        getBrokerAutoScalingGroup(broker.id).then(response => {
            setAutoScalingGroupOutput(response);
        }).catch(error => {
            console.error(error);
            setError("Could not load broker auto scaling group.");
        }).finally(() => {
            setLoading(false);
        })
    }, [broker.id, refreshCnt]);

    function onRefresh() {
        setRefreshCnt(refreshCnt + 1);
    }

    if (error) {
        return <Flashbar items={
            [{
                "type": "error",
                "dismissible": false,
                "content": error
            }]
        }></Flashbar>
    }
    
    return (
        <SpaceBetween direction='vertical' size='l'>
            <Details broker={broker} output={autoScalingGroupOutput} loading={loading} onRefresh={onRefresh} />
            <Instances output={autoScalingGroupOutput} loading={loading} />
            <Activity output={autoScalingGroupOutput} loading={loading} />
        </SpaceBetween>
    )
}

type DetailsPanelProps = {
    broker: Broker,
    output?: DescribeBrokerAutoScalingGroupsOutput,
    onRefresh: () => void,
    loading: boolean
}

const Details : React.FC<DetailsPanelProps> = ({broker, output, loading, onRefresh}) => {
    const config = useConfig();

    if (!config) {
        return <></>
    }
    return (
        <Container header={
            <Header
                actions={
                    <>
                        <Button disabled={loading} iconName="refresh" onClick={() => {
                            onRefresh();
                        }} />
                    </>
                } 
            >Group Details</Header>
        }>
            {loading && <Spinner />}
            {!loading && output &&
                <KeyValuePairs
                    columns={3}
                    items={[
                        {
                            label: 'Auto Scaling Group name',
                            value: output.autoScalingGroupName,
                        },
                        {
                            label: 'ARN',
                            value: <Link target='_blank' href={createIsengardLink(config.isengardEndpoint, broker.brokerInfo.cellAccountId,
                                            'ReadOnly',
                                            `ec2/home?region=${config.region}#AutoScalingGroupDetails:id=${output.autoScalingGroupName};view=details`)}>
                                        {output.autoScalingGroupARN}
                                   </Link>
                        }
                    ]}
                />
            }
        </Container>
    )
}

type InstancesProps = {
    output?: DescribeBrokerAutoScalingGroupsOutput,
    loading: boolean
}

const Instances : React.FC<InstancesProps> = ({output, loading}) => {

    function renderLifecycleState(state: string) {
        if (["InService"].includes(state)) {
            return <StatusIndicator type='success'>{state}</StatusIndicator>;
        } else if (["Pending", "Terminating", "TerminatingWait", "PendingWait", "PendingProceed"].includes(state)) {
            return <StatusIndicator type='in-progress'>{state}</StatusIndicator>;
        } else {
            return <StatusIndicator type='warning'>{state}</StatusIndicator>;
        }
    }

    function renderHealthStatus(healthStatus: string) {
        if (["Healthy"].includes(healthStatus)) {
            return <StatusIndicator type='success'>{healthStatus}</StatusIndicator>;
        } else if (["Unhealthy"].includes(healthStatus)) {
            return <StatusIndicator type='warning'>{healthStatus}</StatusIndicator>;
        } else {
            return <StatusIndicator type='warning'>{healthStatus}</StatusIndicator>;
        }
    }

    return (
        <Table
            loading={loading}
            items={output !== undefined ? output.instances : []}
            columnDefinitions={[
                {
                    id: "instanceId",
                    header: "Instance Id",
                    cell: (item: BrokerAutoScalingGroupInstance) => item.instanceId
                },
                {
                    id: "instanceType",
                    header: "Instance Type",
                    cell: (item: BrokerAutoScalingGroupInstance) => item.instanceType
                },
                {
                    id: "lifecycleState",
                    header: "Lifecycle State",
                    cell: (item: BrokerAutoScalingGroupInstance) => renderLifecycleState(item.lifecycleState)
                },
                {
                    id: "healthState",
                    header: "Health Status",
                    cell: (item: BrokerAutoScalingGroupInstance) => renderHealthStatus(item.healthStatus)
                },
            ]}
            wrapLines={true}
            header={
                <Header>Instances</Header>
            }
        />
    )
}

type ActivityPanelProps = {
    output?: DescribeBrokerAutoScalingGroupsOutput,
    loading: boolean
}

const Activity : React.FC<ActivityPanelProps> = ({output, loading}) => {

    function renderActivityStatus(status: string) {
        if (["Failed"].includes(status)) {
            return <StatusIndicator type='error'>{status}</StatusIndicator>;
        } else if (["InProgress"].includes(status)) {
            return <StatusIndicator type='in-progress'>{status}</StatusIndicator>;
        } else if (["Successful"].includes(status)) {
            return <StatusIndicator type='success'>{status}</StatusIndicator>;
        } else {
            return <StatusIndicator type='warning'>{status}</StatusIndicator>;
        }
    }

    const {
        items,
        collectionProps,
      } = useCollection(output?.activities ?? [], {
        sorting: {
          defaultState: {
            sortingColumn: {
                sortingField: 'startTime'
            },
            isDescending: true,
          },
        },
      });

    return (
        <Table
            {...collectionProps}
            items={items}
            columnDefinitions={[
                {
                    id: "description",
                    header: "Description",
                    cell: (item: BrokerAutoScalingGroupActivity) => item.description,
                    width: 250
                },
                {
                    id: "cause",
                    header: "Cause",
                    cell: (item: BrokerAutoScalingGroupActivity) => item.cause,
                    width: 280
                },
                {
                    id: "startTime",
                    header: "Start Time",
                    sortingField: 'startTime',
                    cell: (item: BrokerAutoScalingGroupActivity) => item.startTime ? isoFormat(new Date(item.startTime)) : ""
                },
                {
                    id: "endTime",
                    header: "End Time",
                    sortingField: 'endTime',
                    cell: (item: BrokerAutoScalingGroupActivity) => item.endTime ? isoFormat(new Date(item.endTime)) : ""
                },
                {
                    id: "status",
                    header: "Status",
                    cell: (item: BrokerAutoScalingGroupActivity) => renderActivityStatus(item.status)
                },
            ]}
            wrapLines={true}
            loading={loading}
            header={
                <Header>Activity</Header>
            }
        />
    )
}

export default AutoScalingGroups;