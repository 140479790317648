import React, { useEffect, useState } from 'react';
import WorkflowsTable from '../../Components/WorkflowsTable/WorkflowsTable';
import { getFailedWorkflows } from '../../api/api';
import { Workflow } from '@amzn/amazonmq-opsconsole-client';
import { ExpandableSection } from '@amzn/awsui-components-react';

const FailedWorkflowsTable : React.FC = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [refreshCnt, setRefreshCnt] = useState<number>(0);
    const [workflows, setWorkflows] = useState<Workflow[] | undefined>(undefined);
    const [failedAccounts, setFailedAccounts] = useState<string[] | undefined>(undefined);
    const [expanded, setExpanded] = useState<boolean>(false);

    useEffect(() => {
        if (!expanded) {
            return;
        }
        setLoading(true);
        setError("");
        setFailedAccounts(undefined);
        getFailedWorkflows().then(response => {
            setWorkflows(response.workflows);
            setFailedAccounts(response.failedAccounts);
        }).catch(error => {
            console.error(error);
            setError("Could not load failed workflows");
        }).finally(() => {
            setLoading(false);
        })
    }, [refreshCnt, expanded]);
    
    return (
         <ExpandableSection headerText='Failed Workflows' expanded={expanded} onChange={(event) => {
            setExpanded(event.detail.expanded)
        }}>
            <WorkflowsTable
                title='Failed Workflows'
                headerDescription="Cell workflows failed in last 3 days"
                workflowFilter={true}
                showHotwWorkflow={false}
                warning={failedAccounts && failedAccounts?.length > 0 ? ("Could not fetch data for accounts: " + failedAccounts.join(", ")) : undefined}
                columns={["workflowName", "brokerId", "cell", "status", "startTime", "endTime"]}
                filter={false} workflows={workflows} error={error} loading={loading} onRefresh={() => {
                setRefreshCnt(refreshCnt + 1);
            }}/>
        </ExpandableSection>
    )

}

export default FailedWorkflowsTable;