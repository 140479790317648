import React from 'react';
// import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import PropTypes from 'prop-types';

class PlotMetric extends React.Component {
    render() {
        return (
                <div>
                    {/* TODO <h2 style={{textAlign:"center"}}> {this.props.graphName} </h2>
                    {this.props.metricsData.length === 0 ? (
                        <h2  style={{textAlign:"center"}}> No metric found </h2>
                    ) : (
                        <ResponsiveContainer height='90%' width='100%'>
                            <LineChart  data={this.props.metricsData} margin={{top: 20, right: 50}}>
                                <XAxis dataKey="date" minTickGap={35}/>
                                <YAxis/>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <Tooltip/>
                                <Legend />
                                <Line type="monotone" dataKey="count" stroke="#82ca9d" />
                            </LineChart>
                        </ResponsiveContainer>
                    )} */}
                </div>
            );
    }
}

PlotMetric.propTypes = {
    graphName: PropTypes.string,
    metricsData: PropTypes.array
};

export default PlotMetric;
