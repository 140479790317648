import { EC2Instance } from '@amzn/amazonmq-opsconsole-client';
import { Select } from '@amzn/awsui-components-react';
import React from 'react';

type Props = {
    instances: EC2Instance[],
    selectedInstance: EC2Instance | undefined,
    onChange: (newInstance: EC2Instance) => void
}
const InstanceSelect : React.FC<Props> = ({instances, selectedInstance, onChange}) => {
    return (
        <Select
            options={instances.map(i => {
                return {
                    label: i.instanceId,
                    value: i.instanceId
                }
            })}
            selectedOption={{
                value: selectedInstance?.instanceId
            }}
            onChange={event => {
                onChange(instances.find(i => i.instanceId === event.detail.selectedOption.value)!)
            }}
        />
    )
}

export default InstanceSelect;