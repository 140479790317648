import { Broker, EC2Instance, LogEvent } from '@amzn/amazonmq-opsconsole-client';
import { DateRangePickerProps, Flashbar, SpaceBetween, Spinner, TextContent } from '@amzn/awsui-components-react';
import React, { useEffect, useMemo, useState } from 'react';
import { getLogEvents } from '../../../api/api';
import LogEventsTable from './LogEventsTable';
import dayjs from 'dayjs';
import { LogEventWithSource, LogSource } from '../../../types';
import { useNavigate } from 'react-router';

type Props = {
    broker: Broker,
    brokerInstances: EC2Instance[] | undefined
}

const BrokerLogs : React.FC<Props> = ({broker, brokerInstances}) => {

    const [error, setError] = useState<string>("");
    const [logEvents, setLogEvents] = useState<LogEventWithSource[] | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>("");
    const [selectedLogSources, setSelectedLogSources] = useState<LogSource[]>([]);
    const [refreshCnt, setRefreshCnt] = useState<number>(0);
    const navigate = useNavigate();
   
    const searchParams = new URLSearchParams(window.location.search);
    const logSources = searchParams.get("logSources");

    useEffect(() => {
        const availableLogSources : LogSource[] | undefined = brokerInstances !== undefined ? createLogSources(brokerInstances) : undefined;
        if (availableLogSources === undefined) {
            return;
        }
        if (!logSources) {
            setSelectedLogSources([]);
        } else {
            const logSourcesList = logSources.split(";");
            const newLogSources = [];
            for (let i = 0; i < logSourcesList.length; i++) {
                const logSource = logSourcesList[i];
                const providedLogGroupName = logSource.split(",")[0];
                const providedLogStreamName = logSource.split(",")[1];
                let selectableLogSource = availableLogSources.find(als => als.logGroupName === providedLogGroupName && als.logStreamName === providedLogStreamName);
                if (selectableLogSource !== undefined) {
                    newLogSources.push(selectableLogSource);
                }
            }
            setSelectedLogSources(newLogSources);
        }
    }, [logSources, brokerInstances, refreshCnt])

    const [dateRange, setDateRange] = useState<DateRangePickerProps.Value>(() => {
        return {
            type: "relative",
            amount: 1,
            unit: "hour" 
        };
    });

    function getSelectedDateRange(dateRange: DateRangePickerProps.Value): {dateFrom: Date, dateTo: Date} {
        if (dateRange.type === "absolute") {
            return {
                dateFrom: new Date(dateRange.startDate),
                dateTo: new Date(dateRange.endDate)
            }
        } else if (dateRange.type === "relative") {
            const endDate = dayjs();
            const startDate = endDate.clone().subtract(dateRange.amount, dateRange.unit);
            return {
                dateFrom: startDate.toDate(),
                dateTo: endDate.toDate()
            };
        } else {
            throw new Error("Unsupported date range type");
        }
    }

    function createLogSources(brokerInstances: EC2Instance[]) {
        const logSources: LogSource[] = [];
        brokerInstances.forEach(instance => {
            logSources.push({
                label: `${instance.instanceId}/general.log`,
                logGroupName: '/amazonmq/rabbitmq-on-host/rabbitmq',
                logStreamName: `${broker.id}/${instance.instanceId}/general.log`,
            })
            logSources.push({
                label: `${instance.instanceId}/metric-agent.log`,
                logGroupName: '/amazonmq/rabbitmq-on-host/metric-agent',
                logStreamName: `${broker.id}/${instance.instanceId}/metric-agent.log`
            })
            logSources.push({
                label: `${instance.instanceId}/rabbitmq-container.log`,
                logGroupName: '/amazonmq/rabbitmq-on-host/rabbitmq-container',
                logStreamName: `${broker.id}/${instance.instanceId}/rabbitmq-container.log`
            })
        })
        return logSources;
    }

    useEffect(() => {
        if (selectedLogSources.length === 0) {
            setError("");
            setLogEvents([]);
            return;
        }
        setError("");
        setLoading(true);
        
        const {dateFrom, dateTo} = getSelectedDateRange(dateRange);

        const promises = selectedLogSources.map(ls => getLogEvents(broker.id, ls.logGroupName, ls.logStreamName, dateFrom, dateTo));

        Promise.all(promises).then(responses => {
            let newLogEvents: LogEventWithSource[] = [];
            for (let i = 0; i < responses.length; i++) {
                const source = selectedLogSources[i];
                const data = responses[i].logEvents;
                for (let j = 0; j < data.length; j++) {
                    newLogEvents.push({
                        logSource: source,
                        logEvent: data[j]
                    })
                }
            }
            setLogEvents(newLogEvents);
        }).catch(error => {
            console.error(error);
            setError("Could not load logs");
        }).finally(() => {
            setLoading(false);
        })
    }, [dateRange, selectedLogSources]);

    if (brokerInstances === undefined) {
        return <Spinner />
    }

    return (
        <SpaceBetween direction='vertical' size='xs'>
            <SpaceBetween direction='vertical' size='xs'>
                {error &&
                    <Flashbar items={
                        [{
                            "type": "error",
                            "dismissible": false,
                            "content": <span style={{whiteSpace: 'pre'}}>{error}</span>
                        }]
                    }></Flashbar>
                }
                {!error &&
                    <LogEventsTable
                        logEvents={logEvents}
                        broker={broker}
                        selectedLogSources={selectedLogSources}
                        onLogSourcesSelected={(newLogSources) => {
                            let queryParts: string[] = [];
                            for (let i = 0; i < newLogSources.length; i++) {
                                queryParts.push(newLogSources[i].logGroupName + "," + newLogSources[i].logStreamName);
                            }
                            navigate(`/broker/${broker.id}/logs?logSources=${encodeURIComponent(queryParts.join(";"))}`);
                        }}
                        logSources={createLogSources(brokerInstances)!}
                        loading={loading}
                        filter={filter}
                        dateRange={dateRange as DateRangePickerProps.Value}
                        onDateRangeUpdated={(newDateRange) => {
                            setDateRange(newDateRange);
                        }}
                        onRefresh={() => {
                            setRefreshCnt(refreshCnt + 1);
                        }}
                        onFilterUpdated={(newFilter) => {
                            setFilter(newFilter);
                        }}
                    />
                }
            </SpaceBetween>
        </SpaceBetween>
    )
}

export default BrokerLogs;