import * as Arn from "@aws-sdk/util-arn-parser";
import { widget } from "../utils";
import { Broker } from "@amzn/amazonmq-opsconsole-client";

export function getBrokerStorageMetrics(broker: Broker): any[] {
    let metrics: any[] = [];
    let region = Arn.parse(broker.brokerInfo.arn).region;

    if (!broker.brokerInfo.efs) {
        return [];
    }

    metrics.push(widget(
        "EFS Throughput",
        region,
        [
            totalIOBytesMetric(broker),
            dataReadIOBytesMetric(broker),
            dataWriteIOBytesMetric(broker)
        ]
    ))

    metrics.push(widget(
        "EFS Load",
        region,
        [
            clientConnectionsMetric(broker),
            percentIOLimitMetric(broker)
        ]
    ))

    return metrics;
}

export function totalIOBytesMetric(broker: Broker): (string | object)[] {

    if (!broker.brokerInfo.efs) {
        return [];
    }

    return [
        "AWS/EFS",
        "TotalIOBytes",
        "FileSystemId",
        broker.brokerInfo.efs.id,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function dataReadIOBytesMetric(broker: Broker): (string | object)[] {

    if (!broker.brokerInfo.efs) {
        return [];
    }
    
    return [
        "AWS/EFS",
        "DataReadIOBytes",
        "FileSystemId",
        broker.brokerInfo.efs.id,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}


export function dataWriteIOBytesMetric(broker: Broker): (string | object)[] {

    if (!broker.brokerInfo.efs) {
        return [];
    }
    
    return [
        "AWS/EFS",
        "DataWriteIOBytes",
        "FileSystemId",
        broker.brokerInfo.efs.id,
        {
            stat: "Sum",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}


export function clientConnectionsMetric(broker: Broker): (string | object)[] {

    if (!broker.brokerInfo.efs) {
        return [];
    }

    return [
        "AWS/EFS",
        "ClientConnections",
        "FileSystemId",
        broker.brokerInfo.efs.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "left"
        }
    ]
}

export function percentIOLimitMetric(broker: Broker): (string | object)[] {

    if (!broker.brokerInfo.efs) {
        return [];
    }
    
    return [
        "AWS/EFS",
        "PercentIOLimit",
        "FileSystemId",
        broker.brokerInfo.efs.id,
        {
            stat: "Average",
            accountId: broker.summary.serviceAccountId,
            yAxis: "right"
        }
    ]
}
