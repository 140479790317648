import { Broker } from "@amzn/amazonmq-opsconsole-client";
import { SideNavigationProps } from "@amzn/awsui-components-react"

export function activeNavItems(broker: Broker): SideNavigationProps.Item[] {
    const brokerId = broker.id;
    let brokerItems: SideNavigationProps.Item[] = [
        { type: 'link', text: `Broker Details`, href: `/broker/${brokerId}/details` },
        { type: 'link', text: `Service Metrics`, href: `/broker/${brokerId}/service-metrics` },
        { type: 'link', text: `Event History`, href: `/broker/${brokerId}/event-history` },
        { type: 'link', text: `Failover History`, href: `/broker/${brokerId}/failover-history` },
    ];
    
    let workflowItems:  SideNavigationProps.Item[] = [
        { type: 'link', text: `Workflows`, href: `/broker/${brokerId}/workflows` },
        { type: 'link', text: `HOTW Executions`, href: `/broker/${brokerId}/hotw-executions` },
        { type: 'link', text: `Code Deploy`, href: `/broker/${brokerId}/codedeploy` },
    ];

    let operationItems: SideNavigationProps.Item[] = [
        { type: 'link', text: `SIM Issues`, href: `/broker/${brokerId}/sim-issues` },
        { type: 'link', text: `Reachout Templates`, href: `/broker/${brokerId}/reachout-templates`},
        { type: 'link', text: `Command Templates`, href: `/broker/${brokerId}/command-templates` },
    ];

    let otherItems: SideNavigationProps.Item[] = [
        { type: 'link', text: `Customer Metrics`, href: `/broker/${brokerId}/customer-metrics` },
        { type: 'link', text: `Service Metrics (Old)`, href: `/broker/${brokerId}/service-metrics-old` },
        { type: 'link', text: `Logs`, href: `/broker/${brokerId}/logs-old` },
    ];

    let navItems: SideNavigationProps.Item[] = [
        { type: 'section', text: 'Broker', items: brokerItems},
        { type: 'divider' },
        { type: 'section', text: 'Workflows', items: workflowItems },
        { type: 'divider' },
        { type: 'section', text: 'Operations', items: operationItems },
        { type: 'divider' },
        { type: 'section', text: 'Other', items: otherItems },
        { type: 'divider' },
        { type: 'link', text: `Runbook`, external: true, href: `https://w.amazon.com/bin/view/AmazonMQ/Ops/Runbook` },
    ];

    return navItems;
}