import React from "react"

import CopyToClipboardButton from './copyToClipboardButton';
import { Link } from "@amzn/awsui-components-react";

export function renderElement(attributeName: string, attributeValue: React.ReactNode, copyText: string) {
    return (
        <div key={attributeName}>
            <div className="awsui-util-label">
                <strong>{attributeName}</strong>
                {copyText &&
                    <CopyToClipboardButton text={copyText}/>
                }
            </div>
            <div>
                <span>{attributeValue}</span>
            </div>
        </div>
    )
}

export function renderField(attributeName: string, attributeValue: string, additionalValue?: string) {
    return (
        <div key={attributeName}>
            <div className="awsui-util-label">
                <strong>{attributeName}</strong>
                <CopyToClipboardButton text={attributeValue}/>
            </div>
            <div>
                <span>{attributeValue}</span>
            </div>
            {additionalValue !== undefined &&
            <div>
                <span>{additionalValue}</span>
            </div>
            }
        </div>
    )
}

export function renderLink(attributeName: string, linkText: string, linkHref: string, textDescription: string = "") {
    return (
        <div key={attributeName}>
            <div className="awsui-util-label">
                <strong>{attributeName}</strong>
                <CopyToClipboardButton text={linkText} textDescription={textDescription} />
            </div>
            <div className="awsui-util-label">
                <Link target="_blank" href={linkHref}>{linkText}</Link>
            </div>
        </div>
    )
}