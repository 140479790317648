import React from 'react';
import { ColumnLayout, Container, Header } from '@amzn/awsui-components-react/polaris';
import { Table } from '@amzn/awsui-components-react';
import useConfig from '../../../hooks/useConfig';
import { Broker } from '@amzn/amazonmq-opsconsole-client';
import { NatGatewayPublicAddress } from "@amzn/amazonmq-opsconsole-client/dist-types/models/models_0";

type Props = {
    broker: Broker,
}

const NetworkingTable : React.FC<Props> = ({broker}) => {

    const appConfig = useConfig();

    if (appConfig == undefined) {
        return <></>
    }

    const brokerInfo = broker.brokerInfo;

    if (brokerInfo.natGatewayPublicIpAddressList == undefined) {
        return <></>
    }

    let columnDefinitions = [
        {
            id: "publicIpv4Address",
            header: "NatGateway Public Addresses",
            cell: (item: NatGatewayPublicAddress) => item.publicIpv4Address
        }
    ]

    return (
        <Table
            items={brokerInfo.natGatewayPublicIpAddressList}
            empty="No NatGateway Public IP Addresses Found"
            columnDefinitions={columnDefinitions}
            loading={false}
        />
    )
}

export default NetworkingTable;