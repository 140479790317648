import {
    CLOUDWATCH_DASHBOARDS,
    EVENT_DASHBOARD_READY,
    EVENT_TYPE_MESSAGE
} from './constants';
import { Dispatcher, generateQueryString, IframeTaskExecutor, validate } from './utils';
import { logCounter, StopWatch } from './utils/metrics';

const NON_PROD_HOSTNAME_MATCHERS = [
    /[.]corp[.]amazon[.]com$/,
    /[.]desktop[.]amazon[.]com$/,
    /[.]aka[.]amazon[.]com$/,
    /[.](iad)[.]amazon[.]com$/,
    /[.]integ[.]amazon[.]com$/,
    /[.]proxy[.]amazon[.]com$/
];

const INTERNAL_ENDPOINTS = {
    gamma: 'https://cw-dashboards-gamma.aka.amazon.com',
    prod: 'https://cw-dashboards.aka.amazon.com'
};

const getInternalEndpoint = stage => INTERNAL_ENDPOINTS[stage] || INTERNAL_ENDPOINTS.prod;

const timers = {
    IFRAME_LOAD: 'iframe_load_time'
};

class EmbeddableView {
    /**
     *
     * @param parent - DOM element to attach the embeddable view to.
     * @param name - unique name of the embeddable view.
     * @param region - region in which the embeddable view has to be accessed.
     * @param config - configuration for the embeddable view.
     * It depends on the subclass being initialized (see https://code.amazon.com/packages/CloudWatchDashboards-Inside/blobs/mainline/--/README.md for details on the different properties).
     * @param style - custom CSS rules to apply to the embeddable view.
     * @param title - accessible contextual title to provide to the iframe.
     * @param partition - partition in which the embeddable view has to be accessed.
     */
    constructor(parent, name, region, config, style, title, partition) {
        validate(parent, 'No parent element defined');
        validate(parent instanceof Element, 'Parent element provided is not a DOM node');
        validate(name, 'No name defined for the view. A unique name is necessary so multiple views on the same page will only process the messages' +
            ' sent to them');
        validate(region, 'No region provided');

        this.parentElement = parent;
        this.region = region;
        this.config = config || {};

        if (this.config.internal) {
            validate(this.config.internal.accountId, 'Account Id has to be provided in internal mode');
        }

        // Create iframe
        const options = {
            config: this.config,
            region,
            origin: `${window.top.location.origin}${window.top.location.pathname}`,
            v: 1,
            awsPartition: partition || undefined
        };

        if (this.config.internal && this.config.internal.accountId) {
            options.accountId = this.config.internal.accountId;
        }

        const iframeElement = document.createElement('iframe');
        iframeElement.src = `${this.iframeUrlPrefix()}/cloudwatch/${this.iframeUrlTarget()}?${generateQueryString(options)}`;
        iframeElement.name = name;
        iframeElement.id = 'embedded-cloudwatch-dashboards';
        iframeElement.sandbox = 'allow-downloads allow-popups allow-same-origin allow-scripts allow-top-navigation';
        iframeElement.setAttribute('allowfullscreen', 'true');

        this.iframeElement = iframeElement;

        // Set iframe style, allowing overrides
        this.updateStyle(style || {});

        parent.appendChild(iframeElement);

        this.taskExecutor = new IframeTaskExecutor(iframeElement);
        this.dispatcher = new Dispatcher();
        this.setupWindowListeners();

        this.stopwatch = new StopWatch();
        this.stopwatch.start(timers.IFRAME_LOAD);
    }

    iframeUrlPrefix() {
        if (this.config.endpoint) {
            return this.config.endpoint; // For custom control of endpoint
        }

        if (this.config.internal) {
            return getInternalEndpoint(this.config.internal.stage);
        }

        const hostName = window.location.hostname;
        return NON_PROD_HOSTNAME_MATCHERS.some(matcher => hostName.match(matcher)) ?
            'https://cloudwatch-console-version-b-test.amazon.com' :
            '';
    }

    updateStyle(style = {}) {
        Object.keys(style).forEach(key => {
            this.iframeElement.style[key] = style[key];
        });
    }

    /**
     * @returns {string} the CloudWatch Console path displayed by the frame. Defaults to a CloudWatch Dashboard.
     */
    iframeUrlTarget() {
        return this.config.internal ? 'dashboardInternal' : 'dashboard';
    }

    /**
     * Handles a specific post message received from iframe embedding the CloudWatch Console.
     * This method has to be overridden by subclasses of EmbeddableView.
     * @param message - Post message received from the iframe.
     */
    handleCloudWatchDashboardsMessage(message) {
        console.debug(message);
        throw new Error('Unsupported operation: handleCloudWatchDashboardsMessage has to be overridden by subclasses of EmbeddableView');
    }

    handleMessage(event) {
        let message;

        try {
            message = JSON.parse(event.data);
        } catch (e) {
            // Not from CloudWatch Dashboards, ignore
            return;
        }

        if (message.sender === CLOUDWATCH_DASHBOARDS && this.iframeElement.contentWindow === event.source) {

            logCounter(`event_${message.event}`);

            if (message.event === EVENT_DASHBOARD_READY) {
                this.stopwatch.stop(timers.IFRAME_LOAD);
            }

            this.handleCloudWatchDashboardsMessage(message);
        }
    }

    setupWindowListeners() {
        this.messageListener = this.handleMessage.bind(this);

        window.addEventListener(EVENT_TYPE_MESSAGE, this.messageListener);
    }

    release() {
        if (!!this.messageListener) {
            window.removeEventListener(EVENT_TYPE_MESSAGE, this.messageListener);
        }
        this.dispatcher.release();
    }

    on(event, callback) {
        this.dispatcher.on(event, callback);
    }

    off(event, callback) {
        this.dispatcher.off(event, callback);
    }
}

export default EmbeddableView;
