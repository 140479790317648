import React from 'react';
import { CopyToClipboard } from '@amzn/awsui-components-react/polaris';

type Props = {
    textDescription?: string;
    text: string;
    variant?: 'icon' | 'button';
    copyButtonText?: string
}

const CopyToClipboardButton : React.FC<Props> = ({textDescription, copyButtonText, variant = 'icon', text}) => {

    const truncateString = (str: string | undefined) =>  str === undefined || str === null ? "" : (str.length > 7) ? str.substr(0, 10-1) + '...' : str;
    return (
        <CopyToClipboard
            variant={variant}
            copyErrorText="Failed to copy"
            copyButtonText={copyButtonText}
            copySuccessText={`${textDescription || truncateString(text)} copied!`}
            textToCopy={text}
        />
    )

}

export default CopyToClipboardButton;