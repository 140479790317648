import React, { useEffect, useState } from 'react';
import { Flashbar, Link, Table, TextFilter } from '@amzn/awsui-components-react/polaris';
import { getSimIssues } from '../../../api/api';
import { Broker, SimIssue } from '@amzn/amazonmq-opsconsole-client';

type Props = {
    broker: Broker,
}

const SimIssues : React.FC<Props> = ({broker}) => {

    const [issues, setIssues] = useState<SimIssue[]>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [filteringText, setFilteringText]  = useState<string>("");

    useEffect(() => {
        setLoadingData(true);
        setError("");
        getSimIssues(broker.id, broker.snapshotId).then(response => {
            setIssues(response.simIssues);
        }).catch(error => {
            console.error(error);
            setError("Could not load SIM Issues.");
        }).finally(() => {
            setLoadingData(false);
        })
    }, [broker.id, broker.snapshotId]);

    let columnDefinitions = [
        {
            id: "creationDate",
            header: "Creation Date",
            cell: (item: SimIssue) => item.creationDate
        },
        {
            id: "title",
            header: "Issue Title",
            cell: (item: SimIssue) => <Link external={true} href={item.simLink} target="_blank">{item.title}</Link>
        }
    ];


    return (
        <>
            {error && <Flashbar items={
                            [{
                                "type": "error",
                                "dismissible": false,
                                "content": error
                            }]
                        }></Flashbar>}
            {!error &&
                <Table
                    items={issues.filter(i => i.title && i.title.toLowerCase().includes(filteringText.toLowerCase()))}
                    filter={
                        <TextFilter
                            filteringPlaceholder="Search issue"
                            filteringText={filteringText}
                            onChange={(event) => {
                            setFilteringText(event.detail.filteringText);
                            }}
                        />
                        }
                    columnDefinitions={columnDefinitions}
                    loading={loadingData}
                    empty="No SIM Issues found"
                />
            }
        </>
    )
}

export default SimIssues;
