import React, { useEffect, useState } from 'react';

import { Button, Icon, Popover } from '@amzn/awsui-components-react/polaris';
import { getSimMarkdown } from '../../api/api';

const LOADING_STATE = {
    LOADING: 'LOADING',
    LOADED: 'LOADED',
    FAILED: 'FAILED',
    UNLOADED: 'UNLOADED'
};

type CopyMarkDownButtonProps = {
    brokerId: string,
    snapshotId: string,
}

const CopyMarkDownButton : React.FC<CopyMarkDownButtonProps> = ({brokerId, snapshotId}) => {
    
    const [loadingState, setLoadingState] = useState<string>(LOADING_STATE.UNLOADED);
    const [markdownData, setMarkdownData] = useState<string | undefined>(undefined);

    useEffect(() => {
        fetchSimMarkdown(false);
    }, [])

    function isMarkdownLoaded() {
        return loadingState === LOADING_STATE.LOADED && markdownData !== undefined;
    }

    function cantCopyText() {
        alert('Temporary Error? Could not write to clipboard.');
    }

    const loadMarkdown = () => {
        fetchSimMarkdown(true);
    };

    function copySimMarkdown() {
        if (!isMarkdownLoaded()) {
            return cantCopyText();
        }
        if (markdownData === undefined) {
            return;
        }
        navigator.clipboard.writeText(markdownData)
            .catch(() => cantCopyText());
    }

    function fetchSimMarkdown(alertOnFail = false) {
        setLoadingState(LOADING_STATE.LOADING);
        getSimMarkdown(brokerId, snapshotId).then(response => {
            // f (response.ok && responseText.startsWith('#')) {
            setLoadingState(LOADING_STATE.LOADED);
            setMarkdownData(response.markdown);
        }).catch(error => {
            console.error(error);
            setLoadingState(LOADING_STATE.FAILED);
            if (alertOnFail) {
                alert('Failed to obtain markdown (console has details)');
            }
        });
    }

    switch (loadingState) {
        case LOADING_STATE.LOADING:
            return <Button iconName="status-pending" disabled={true}>Loading Markdown</Button>
        case LOADING_STATE.UNLOADED:
            return <Button iconName="share" onClick={() => loadMarkdown()}>Fetch Markdown</Button>
        case LOADING_STATE.FAILED:
            // for some reason uncommenting the icon here causes React to crash with infinite loop
            // if failed state occurred.
            return <Button /* icon="status-refresh" */ onClick={() => loadMarkdown()}>Retry Fetch Markdown</Button>
        case LOADING_STATE.LOADED:
            return <Popover
                size="small"
                position="bottom"
                triggerType="custom"
                dismissButton={false}
                content={
                    <span className="awsui-util-status-positive">
                        <Icon variant="success" name="status-positive"/> SIM Markdown Copied!
                    </span>
                }>
                <Button iconName="copy" variant="primary" onClick={() => copySimMarkdown()}>
                Copy Markdown
                </Button>
            </Popover>
    }
    return <Icon variant="error" name="status-negative"/>

}

export default CopyMarkDownButton;