import { calculateDuration, logCounter, logTimer } from './metrics';

const IFRAME_TIMEOUT = 60 * 1000;
const METRIC_IFRAME_TIMEOUT = 'iframe_timeout';
const METRIC_AVAILABILITY = 'availability';

/**
 * Helper class to store tasks and execute them only when embedded
 * dashboard is able to process the requests
 */
class IframeTaskExecutor {
    constructor(iframeElement) {
        this.iframeElement = iframeElement;
        this.queue = {};
        this.pending = true;

        const self = this;

        setTimeout(() => {
            // Dashboard couldn't be loaded in 60 seconds and iframe is still part of DOM (user hasn't navigated away),
            // report timeout error
            if (self.pending && document.body.contains(iframeElement)) {
                logCounter(METRIC_IFRAME_TIMEOUT, 1);
                logCounter(METRIC_AVAILABILITY, 0);
            }
        }, IFRAME_TIMEOUT);
    }

    ready() {
        // Making sure ready won't have effect if called multiple times
        if (!this.pending) {
            return false;
        }

        this.pending = false;
        this.tryExecute();

        logCounter(METRIC_IFRAME_TIMEOUT, 0);
        logCounter(METRIC_AVAILABILITY, 100);

        return true;
    }

    enqueue(task) {
        // Making sure that we keep only the last task of the same actions
        this.queue[task.action] = {
            timestamp: new Date(),
            task
        };
        this.tryExecute();
    }

    tryExecute() {
        if (!this.pending) {
            const { contentWindow, src } = this.iframeElement;

            Object.keys(this.queue).forEach(key => {
                const element = this.queue[key];

                // Log timing
                logTimer(`${key}_time`, calculateDuration(element.timestamp));

                contentWindow.postMessage(JSON.stringify(element.task), src);
                delete this.queue[key];
            });
        }
    }
}

export default IframeTaskExecutor;