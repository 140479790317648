const METRIC_PREFIX = 'cwdbi_';

export const logTimer = (timerName, timeMs) => {
    try {
        window.AWSC.Timer.submitCustomTimer(`${METRIC_PREFIX}${timerName}`, timeMs);
    } catch (e) {
        // Something went wrong when adding timer value
        // Ignore error silently
    }
};

export const logCounter = (counterName, counterValue = 1) => {
    try {
        window.AWSC.Clog.log(`${METRIC_PREFIX}${counterName}`, counterValue);
    } catch (e) {
        // Something went wrong when adding counter value
        // Ignore error silently
    }
};


export const calculateDuration = startTime => {
    const now = new Date();
    return now - startTime;
};

export class StopWatch {

    constructor() {
        this.timers = {}
    }

    start(timerName) {
        this.timers[timerName] = new Date();
    }

    stop(timerName) {
        const startTime = this.timers[timerName];
        if (startTime) {
            logTimer(timerName, calculateDuration(startTime));
            delete this.timers[timerName];
        }
    }

}