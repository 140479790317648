import React from 'react';
import { TextContent, Badge } from '@amzn/awsui-components-react/polaris';
import CopyToClipboardButton from './copyToClipboardButton';
import CopyMarkDownButton from './CopyMarkDownButton';
import BrokerSummaryTable from './BrokerSummaryTable';
import { Broker, EC2Instance } from '@amzn/amazonmq-opsconsole-client';

type Props = {
    broker: Broker,
    brokerInstances: EC2Instance[] | undefined
}

const BrokerHeader : React.FC<Props> = ({broker, brokerInstances}) => {
    return (
        <div style={{display: 'flex', gap: 8, flexDirection: 'column', marginBottom: 16}}>
            <div style={{display: 'flex', gap: 8, flexDirection: 'row', alignItems: 'center',  marginBottom: 8}}>
                <TextContent>
                    <h2>{broker.id}</h2>
                </TextContent>
                <CopyToClipboardButton
                    text={broker.id}
                    textDescription={broker.id}
                />
                {broker.summary.ourBroker && <Badge color="blue">Our Broker</Badge>}
                <span style={{flexGrow: 1}}></span>
                <CopyMarkDownButton
                    brokerId={broker.id}
                    snapshotId={broker.snapshotId}
                />
            </div>
            <BrokerSummaryTable
                broker={broker}
                brokerMaintenance={broker.maintenanceInfo}
                brokerInstances={brokerInstances}
            />
        </div>
    )
}

export default BrokerHeader;