import React from 'react';
import {Box, Button, Pagination, Popover, SpaceBetween, StatusIndicator, Table} from '@amzn/awsui-components-react';
import {GroupedRabbitBrokerTimelineEvent} from "../../../types";
import {copyMarkdown} from "./logEventsUtils";

interface LogTableContentProps {
    isDupeMode: boolean;
    loading: boolean;
    hasInteracted: boolean;
    columnDefinitions: (isDupeMode: boolean) => any;
    groupedEvents: GroupedRabbitBrokerTimelineEvent[];

}

const LogTableContent = ({
                             isDupeMode,
                             loading,
                             hasInteracted,
                             columnDefinitions,
                             groupedEvents
                         }: LogTableContentProps) => {

    return (
        <Table
            loading={loading}
            renderAriaLive={({
                                 firstIndex,
                                 lastIndex,
                                 totalItemsCount,
                             }) =>
                `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
            }
            columnDefinitions={columnDefinitions(isDupeMode)}
            items={groupedEvents}
            loadingText="Loading resources"
            resizableColumns
            pagination={<Pagination currentPageIndex={1} pagesCount={1}/>}
            empty={
                hasInteracted && (
                    <Box
                        margin={{vertical: "xs"}}
                        textAlign="center"
                        color="inherit"
                    >
                        <SpaceBetween size="m">
                            <b>No matches</b>
                        </SpaceBetween>
                    </Box>
                )
            }
            filter={<Popover
                dismissButton={false}
                position="top"
                size="small"
                triggerType="custom"
                content={
                    <StatusIndicator type="success">
                        Markdown copied
                    </StatusIndicator>
                }
            >
                <Button disabled={loading || !groupedEvents} onClick={() => {
                    copyMarkdown(groupedEvents);
                }}>Copy Markdown</Button>
            </Popover>}
        />
    )
}

export default LogTableContent;