import React, { useEffect, useState } from 'react';
import { Header, Spinner, Table, TextContent, TextFilter } from '@amzn/awsui-components-react/polaris';
import CopyToClipboardButton from '../../Components/copyToClipboardButton';
import { getDomainFromHost } from '../../../utils';
import rabbitData from './rabbit-data.json';
import activeData from './active-data.json';
import InstanceSelect from '../../Components/InstanceSelect';
import { Broker, BrokerEngineType, EC2Instance } from '@amzn/amazonmq-opsconsole-client';

type Props = {
    broker: Broker,
    brokerInstances: EC2Instance[] | undefined,
}

type CommandTemplate = {
    title: string
    command: string,
}

const CommandTemplates : React.FC<Props> = ({broker, brokerInstances}) => {

    const [selectedInstance, setSelectedInstance] = useState<EC2Instance | undefined>(undefined);
    const [filteringText, setFilteringText] = useState<string>("");

    useEffect(() => {
       if (brokerInstances === undefined) {
            setSelectedInstance(undefined);
       } else {
            setSelectedInstance(brokerInstances.length === 0 ? undefined : brokerInstances[0]);
       }
    }, [brokerInstances]);

    function generateAllCommands(broker: Broker): CommandTemplate[] {

        let data = broker.summary.brokerEngineType === BrokerEngineType.ACTIVEMQ ? activeData : rabbitData;
        let ret = [];

        const accountId = broker.summary.accountId;
        const cellAccountId = broker.brokerInfo.cellAccountId;
    
        const domain = getDomainFromHost();
    
        for (let i = 0; i < data.commands.length; i++) {
            let command = data.commands[i].command
            
            command = command.replaceAll('<account-id>', accountId)
                .replaceAll('<region>', broker.region)
                .replaceAll('<region-not-airport>', broker.regionName)
                .replaceAll('<cell-account-id>', cellAccountId)
                .replaceAll('<cell>', broker.brokerInfo.cellId)
                .replaceAll('<domain>', domain)
                .replaceAll('<broker-id>', broker.id)
                .replaceAll('<instance-id>', selectedInstance !== undefined ? selectedInstance.instanceId : "")
    
            ret.push({
                command: command,
                title: data.commands[i].title
            })
        }
    
        return ret
    }

    let commandTemplates = generateAllCommands(broker);

    if (brokerInstances === undefined) {
        return <Spinner />
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: 12}}>
            <div>
                <TextContent><p>Instance Id:</p></TextContent>
                <InstanceSelect
                    instances={brokerInstances}
                    selectedInstance={selectedInstance}
                    onChange={(newInstance) => {
                        setSelectedInstance(newInstance)
                    }}/>
            </div>
            <Table
                items={commandTemplates.filter(t => t.title && t.title.toLowerCase().includes(filteringText.toLowerCase()))}
                header={
                    <Header variant="h2">
                        Command Templates
                    </Header>
                }
                filter={
                    <TextFilter
                        filteringPlaceholder="Search Command"
                        filteringText={filteringText}
                        onChange={(event) => {
                            setFilteringText(event.detail.filteringText);
                        }}
                    />
                }
                wrapLines={true}
                columnDefinitions={[
                    {
                        id: "title",
                        header: "Title",
                        cell: (item: CommandTemplate) => item.title
                    },
                    {
                        id: "command",
                        header: "Command",
                        cell: (item: CommandTemplate) => item.command
                    },
                    {
                        id: "copy",
                        header: "",
                        cell: (item: CommandTemplate) =>  <CopyToClipboardButton
                                         text={item.command}
                                         textDescription={'Command Template'}
                                        />
                    }
                ]}
            />
        </div>
    )

}

export default CommandTemplates;
