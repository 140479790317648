import React, { useEffect, useState } from 'react';
import { getEvents } from '../../../api/api';
import { Table } from '@amzn/awsui-components-react';
import { Broker, BrokerEvent } from '@amzn/amazonmq-opsconsole-client';

type Props = {
    broker: Broker,
}

const EventHistory : React.FC<Props> = ({broker}) => {

    const [events, setEvents] = useState<BrokerEvent[]>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);

    useEffect(() => {
        setLoadingData(true);
        getEvents(broker.id).then(response => {
            setEvents(response.events);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setLoadingData(false);
        })
    }, [broker.id]);

  
    let columnDefinitions = [
        {
            id: "brokerState",
            header: "Broker State",
            cell: (item: BrokerEvent) => item.brokerState
        },
        {
            id: "eventType",
            header: "Event Type",
            cell: (item: BrokerEvent) => item.eventType
        },
        {
            id: "message",
            header: "Message",
            cell: (item: BrokerEvent) => item.message
        },
        {
            id: "eventData",
            header: "Event Data",
            cell: (item: BrokerEvent) => item.eventData
        },
        {
            id: "timeStamp",
            header: "timestamp",
            cell: (item: BrokerEvent) => item.timeStamp,
            sortingField: 'timeStamp'
        }
    ]

    return (
        <div className="infoTable">
            <Table
                items={events}
                empty="No Broker Events found"
                wrapLines={true}
                columnDefinitions={columnDefinitions}
                loading={loadingData}
                sortingColumn={{sortingField: 'timeStamp'}}
                sortingDescending={true}
            />
        </div>
    );

}

export default EventHistory;
