const DEFAULT_TOOLBAR_HEIGHT = 66;
const CARD_TOOLBAR_HEIGHT = 45;

const GRID_COLUMN_COUNT = 24;

const DEFAULT_WIDGETS_PER_ROW = 3;

const DEFAULT_WIDGET_TYPE = 'metric';

export const DEFAULT_CHART_HEIGHT_UNITS = 6;

export const generateModel = (dashboardModel, widgetHeightInUnits, widgetsPerRow = DEFAULT_WIDGETS_PER_ROW) => {
    const widgetWidthInUnits = Math.floor(GRID_COLUMN_COUNT / widgetsPerRow);

    let maxY = 0;

    const widgets = dashboardModel.widgets.map((widget, index) => {
        const x = (index % widgetsPerRow) * widgetWidthInUnits;
        const y = Math.floor(index / widgetsPerRow) * widgetHeightInUnits;

        if (y > maxY) {
            maxY = y;
        }

        return {
            properties: widget.properties,
            height: widgetHeightInUnits,
            width: widgetWidthInUnits,
            type: widget.type || DEFAULT_WIDGET_TYPE,
            x,
            y
        };
    });

    return {
        model: Object.assign({}, dashboardModel, { widgets }),
        metaData: {
            maxY
        }
    };
};

const getToolbarHeight = style => {
    return style === 'polarisCard' ?
        CARD_TOOLBAR_HEIGHT :
        DEFAULT_TOOLBAR_HEIGHT;
};

export const calculateDashboardHeight = (bottomInUnits, unitHeight, margin, style) => {
    return bottomInUnits * unitHeight + margin * (bottomInUnits - 1) + getToolbarHeight(style);
};

export const getMaxYFromModel = model => {
    let maxY = 0;
    if (model.widgets) {
        model.widgets.forEach(widget => {
            if (widget.y + widget.height > maxY) {
                maxY = widget.y + widget.height;
            }
        });
    }
    return maxY;
};