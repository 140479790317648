import React, { useEffect, useState } from 'react';
import { Broker, EC2Instance, InternalException, QuorumStatus } from '@amzn/amazonmq-opsconsole-client';
import { Button, FormField, Header, Input, Popover, Select, SpaceBetween, Spinner, StatusIndicator, Table, TableProps, TextContent } from '@amzn/awsui-components-react';
import { useNavigate, useParams } from 'react-router-dom';
import { BrokerInstance } from '../../../types';
import { getQuorumStatus } from '../../../api/api';

type Props = {
    broker: Broker,
    brokerInstances: EC2Instance[] | undefined
}

const DescribeQueue : React.FC<Props> = ({broker, brokerInstances}) => {

    const params = useParams();
    const [selectedInstance, setSelectedInstance] = useState<BrokerInstance | undefined>(undefined);
    const [warning, setWarning] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [quorumStatus, setQuorumStatus] = useState<QuorumStatus[] | undefined>(undefined);
    const [error, setError] = useState<string>("");
    const [refreshCnt, setRefreshCnt] = useState<number>(0);
    const [vhostRaw, setVhostRaw] = useState<string>("/");
    const [queueNameRaw, setQueueNameRaw] = useState<string>("");
    const [vhostError, setVhostError] = useState<string>("");
    const [queueNameError, setQueueNameError] = useState<string>("");
    const navigate = useNavigate();

    let idx = params["*"] ? params["*"].lastIndexOf('/') : -1;

    const vhost = idx >= 0 ? params["*"]?.substring(0, idx) : "/";
    const queueName = idx >= 0 ? params["*"]?.substring(idx + 1) : "";

    let columnDefinitions: TableProps.ColumnDefinition<QuorumStatus>[] = [
        {
            id: "nodeName",
            header: "Node",
            cell: item => item.nodeName
        },
        {
            id: "raftState",
            header: "Raft State",
            cell: item => item.raftState
        },
        {
            id: "logIndex",
            header: "Log Index",
            cell: item => item.logIndex
        },
        {
            id: "commitIndex",
            header: "Commit Index",
            cell: item => item.commitIndex
        },
        {
            id: "snapshotIndex",
            header: "Snapshot Index",
            cell: item => item.snapshotIndex
        },
        {
            id: "term",
            header: "Term",
            cell: item => item.term
        },
        {
            id: "machineVersion",
            header: "Machine Version",
            cell: item => item.machineVersion
        },
    ];

    useEffect(() => {
        if (brokerInstances && brokerInstances.length > 0) {
            setSelectedInstance(brokerInstances[0]);
        }
    }, [brokerInstances])

    useEffect(() => {
        if (selectedInstance == undefined || !vhost || !queueName) {
            return;
        }
        setVhostRaw(vhost || "");
        setQueueNameRaw(queueName || "");
        setVhostError("");
        setQueueNameError("");
        setError("");
        setLoading(true);
        setWarning("");

        getQuorumStatus(broker.id, selectedInstance.instanceId, vhost, queueName).then(response => {
            setQuorumStatus(response.quorumStatus);
        }).catch(error => {
            if (error instanceof InternalException && error.message) {
                setError(error.message);
            } else {
                console.error(error);
                setError("Could not get quorum status");
            }
        }).finally(() => {
            setLoading(false);
        })
    }, [vhost, queueName, refreshCnt, selectedInstance?.instanceId])

    function onRefresh() {
        setRefreshCnt(refreshCnt + 1);
    }

    function validate() {
        setVhostError("");
        setQueueNameError("");
        let valid = true;
        if (!vhostRaw) {
            setVhostError("Enter vhost");
            valid = false;
        }
        if (!queueNameRaw) {
            setQueueNameError("Enter queue name");
            valid = false;
        }
        return valid;
    }

    function submit() {
        if (validate()) {
            navigate(`/broker/${broker.id}/quorum-status/${encodeURIComponent(vhostRaw)}/${encodeURIComponent(queueNameRaw)}`);
            setRefreshCnt(refreshCnt + 1);
        }
    }

    function copyMarkdown() {
        if (quorumStatus === undefined) {
            return;
        }
        let markdown = `\n### Queue ${queueName} on vhost ${vhost} quorum status\n`;
        markdown += "---\n";
        markdown += "|Node|Raft State|Log Index|Commit Index|Snapshot Index|Term|Machine Version|\n"
        markdown += "|-|-|-|-|-|-|-\n"
        quorumStatus.forEach(({nodeName, raftState, logIndex, commitIndex, snapshotIndex, term, machineVersion}) => {
            markdown += `|${nodeName}|${raftState}|${logIndex}|${commitIndex}|${snapshotIndex}|${term}|${machineVersion}\n`;
        })
        navigator.clipboard
            .writeText(markdown)
            .catch(() => {
                console.error("Could not copy markdown.");
            });
    }

    if (brokerInstances === undefined) {
        return <Spinner />
    }

    return (
        <SpaceBetween direction='vertical' size='xs'>
            <div style={{marginBottom: 8}}>
                <TextContent><p>Instance Id:</p></TextContent>
                <Select
                    options={brokerInstances.map(i => ({value: i.instanceId, id: i.instanceId}))}
                    selectedOption={{
                        value: selectedInstance?.instanceId,
                        label: selectedInstance?.instanceId,
                    }}
                    onChange={(event: any) => {
                        if (brokerInstances === undefined) {
                            return;
                        }
                        setSelectedInstance(brokerInstances.find(i => i.instanceId === event.detail.selectedOption.value))
                    }}
                />
            </div>
            <Table
                items={error || !quorumStatus ? [] : quorumStatus}
                columnDefinitions={columnDefinitions}
                wrapLines={true}
                loading={loading}
                empty={(!vhost || !queueName) ? "Enter vhost and queue name" : ""}
                header={
                    <Header
                    
                        description={<SpaceBetween direction='vertical' size='xs'>
                            {warning && <StatusIndicator type='warning'>{warning}</StatusIndicator> }
                            {error && <StatusIndicator type='error'>{error}</StatusIndicator> }
                            </SpaceBetween>}
                        actions={
                            <SpaceBetween size='xs' direction='horizontal'>
                                <Popover
                                    dismissButton={false}
                                    position="top"
                                    size="small"
                                    triggerType="custom"
                                    content={
                                        <StatusIndicator type="success">
                                          Markdown copied
                                        </StatusIndicator>
                                    }
                                >
                                    <Button disabled={loading || !quorumStatus} onClick={() => {
                                        copyMarkdown();
                                    }}>Copy Markdown</Button>
                                </Popover>
                                <Button disabled={loading || !quorumStatus} iconName="refresh" onClick={() => {
                                    onRefresh();
                                }} />
                            </SpaceBetween>
                        } 
                    >
                        <SpaceBetween direction='horizontal' size='xs'>
                            <FormField label="Vhost" errorText={vhostError}>
                                <Input value={vhostRaw || ""} onKeyDown={(event) => {
                                    if (event.detail.key === 'Enter') {
                                        submit();
                                    }
                                }} onChange={(event) => {
                                    setVhostRaw(event.detail.value)
                                }}/>
                            </FormField>
                            <FormField label="Queue" errorText={queueNameError}>
                                <SpaceBetween direction='horizontal' size='xs'>
                                    <Input value={queueNameRaw || ""} onKeyDown={(event) => {
                                        if (event.detail.key === 'Enter') {
                                            submit();
                                        }
                                    }} onChange={(event) => {
                                        setQueueNameRaw(event.detail.value)
                                    }}/>
                                    <Button disabled={loading} variant='primary' onClick={() => {
                                        submit();
                                    }}>Get Quorum Status</Button>
                                </SpaceBetween>
                            </FormField>
                        </SpaceBetween>
                    </Header>
                }
            />
        </SpaceBetween>
    )
}

export default DescribeQueue;