import React, { useEffect, useState } from 'react';
import { Container, Flashbar, Header, Spinner } from  '@amzn/awsui-components-react/polaris';
import JSONPretty from 'react-json-pretty';
import { getRabbitClusterStatus } from '../../../api/api';
import CopyToClipboardButton from '../../Components/copyToClipboardButton';
import {Broker, EC2Instance} from '@amzn/amazonmq-opsconsole-client';
import { ClusterStatus } from '../../../types';
import {TextContent} from "@amzn/awsui-components-react";
import InstanceSelect from "../../Components/InstanceSelect";


type Props = {
    broker: Broker,
    brokerInstances: EC2Instance[] | undefined,
}

const ClusterStatusPage : React.FC<Props> = ({broker, brokerInstances}) => {
    const [clusterStatus, setClusterStatus] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedInstance, setSelectedInstance] = useState<EC2Instance | undefined>(undefined);

    useEffect(() => {
        if (brokerInstances === undefined) {
            setSelectedInstance(undefined);
        } else {
            setSelectedInstance(brokerInstances.length === 0 ? undefined : brokerInstances[0]);
        }
    }, [brokerInstances]);

    useEffect(() => {
        setLoading(true);
        setError("");
        getRabbitClusterStatus(broker.id, selectedInstance?.instanceId, "table").then(response => {
            setClusterStatus((response.clusterStatus as any).plainText);
        }).catch(error => {
            console.error(error);
            setError("Could not load cluster status");
        }).finally(() => {
            setLoading(false);
        });
    
    }, [broker.id, brokerInstances, selectedInstance]);

    if (brokerInstances === undefined) {
        return <Spinner />
    }

    return (
        <div>
            {error && <Flashbar items={
                [{
                    "type": "error",
                    "dismissible": false,
                    "content": error
                }]
            }></Flashbar>}
            {<div style={{marginBottom: 8}}>
                <TextContent><p>Instance Id:</p></TextContent>
                <InstanceSelect
                    instances={brokerInstances}
                    selectedInstance={selectedInstance}
                    onChange={(newInstance) => {
                        setSelectedInstance(newInstance)
                    }}/>
            </div>
            }
                <Container header={
                    <Header variant='h2'>
                        Cluster Status
                    </Header>
                }>
                     {loading && <Spinner /> }
                     {!loading && !error && clusterStatus &&
                        <>
                            <CopyToClipboardButton text={JSON.stringify(clusterStatus)} />
                            <JSONPretty data={clusterStatus}></JSONPretty>
                        </>
                     }
                </Container>
        </div>
    );
}

export default ClusterStatusPage;