import React, { memo } from 'react';
import CloudWatchDashboard from './CloudWatchDashboard';
import { getBrokerStorageMetrics } from './metrics/brokerStorageMetrics';
import { Broker } from '@amzn/amazonmq-opsconsole-client';

type Props = {
    broker: Broker,
}

const BrokerStorageTab : React.FC<Props> = ({broker}) => {
    let widgets = getBrokerStorageMetrics(broker);

    return (
        <div>
            <CloudWatchDashboard
                broker={broker}
                layoutType='automatic'
                widgetsPerRow={2}
                serviceAccountId={broker.summary.serviceAccountId}
                widgets={widgets}
            /> 
        </div>
    )
}

export default memo(BrokerStorageTab);