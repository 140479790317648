import { AppLayout, ContentLayout, Header } from '@amzn/awsui-components-react/polaris';
import React from 'react';
import useConfig from '../hooks/useConfig';
import RegionDashboard from './RegionDashboard/RegionDashboard';

const MainPage : React.FC = () => {

    const appConfig = useConfig();

    return (
        <AppLayout
            toolsHide={true}
            navigationHide={true}
            content={
                <ContentLayout header={
                    <Header variant="h2">
                        {appConfig?.region} dashboard
                    </Header>
                }>
                    <RegionDashboard />
                </ContentLayout>
            }
        />
    )
    
}

export default MainPage;