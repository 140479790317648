import React, { useEffect, useState } from 'react';

import {
    Button,
    Checkbox,
    Flashbar,
    Header,
    Select,
    SpaceBetween,
    Table,
    TextContent,
} from '@amzn/awsui-components-react/polaris';
import { getRabbitBrokerQueues } from '../../../api/api';
import CopyToClipboardButton from '../../Components/copyToClipboardButton';
import { Broker, EC2Instance, RabbitBrokerQueue } from '@amzn/amazonmq-opsconsole-client';
import { BrokerInstance } from '../../../types';
import { useNavigate } from 'react-router';

type SummaryProps = {
    brokerQueues: RabbitBrokerQueue[] | undefined,
    onStateToggle: (queueState: string) => void,
    onTypeToggle: (queueType: string) => void,
    enabledStates: string[],
    enabledTypes: string[]
}

function getCountByState(brokerQueues: RabbitBrokerQueue[] | undefined): Map<string, number> {
    if (brokerQueues === undefined) {
        return new Map();
    }

    let countByState = new Map<string, number>();
    for (let i = 0; i < brokerQueues.length; i++) {
        let queue = brokerQueues[i];
        if (!countByState.get(queue.state!)) {
            countByState.set(queue.state!, 1);
        } else {
            let currentCount : number = countByState.get(queue.state!) || 0;
            countByState.set(queue.state!, currentCount + 1);
        }
    }
    return countByState;

}

function getCountByType(brokerQueues: RabbitBrokerQueue[] | undefined): Map<string, number> {
    if (brokerQueues === undefined) {
        return new Map();
    }

    let countByType: Map<string, number> = new Map<string, number>();

    brokerQueues.forEach(queue => {
        if (countByType.has(queue.type!)) {
            countByType.set(queue.type!, countByType.get(queue.type!)! + 1);
        } else {
            countByType.set(queue.type!, 1);
        }
    })

    return countByType;
}

const BrokerQueuesSummary : React.FC<SummaryProps> = ({brokerQueues, enabledStates, onStateToggle, enabledTypes, onTypeToggle}) => {
    let countByState = getCountByState(brokerQueues);
    let countByType: Map<string, number> = getCountByType(brokerQueues);
    return (
        <div style={{display: 'flex', gap: 8, flexDirection: 'row', alignItems: 'center'}}>
            {[...countByState].map(([queueState, numberOfQueues]) => {
                return (
                    <div>
                        <Checkbox key={`queue-state-checkbox-${queueState}`} checked={enabledStates.indexOf(queueState) >= 0} onChange={(e) => {
                            onStateToggle(queueState)
                        }}>{queueState} ({numberOfQueues})</Checkbox>
                    </div>
                )
            })}
            {[...countByType].map(([queueType, numberOfQueues]) => {
                return (
                    <div>
                        <Checkbox key={`queue-type-checkbox-${queueType}`} checked={enabledTypes.indexOf(queueType) >= 0} onChange={(e) => {
                            onTypeToggle(queueType)
                        }}>Queue Type: {queueType} ({numberOfQueues})</Checkbox>
                    </div>
                )
            })}
        </div>
    )
}

type Props = {
    broker: Broker,
    brokerInstances: EC2Instance[] | undefined
}


const BrokerQueues : React.FC<Props> = ({broker, brokerInstances}) => {

    const [selectedInstance, setSelectedInstance] = useState<BrokerInstance | undefined>(undefined);
    const [brokerQueues, setBrokerQueues] = useState<RabbitBrokerQueue[] | undefined>(undefined);
    const [refreshCnt, setRefreshCnt] = useState<number>(0);
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [enabledStates, setEnabledStates] = useState<string[]>(Array.from(getCountByState(brokerQueues).keys()));
    const [enabledTypes, setEnabledTypes] = useState<string[]>(Array.from(getCountByType(brokerQueues).keys()));

    useEffect(() => {
        setLoading(true);
        setError("");
        getRabbitBrokerQueues(broker.id, selectedInstance?.instanceId).then(response => {
            setBrokerQueues(response.queues);
            if (refreshCnt === 0) {
                let defaultEnabledStates = Array.from(getCountByState(response.queues).keys());
                setEnabledTypes(Array.from(getCountByType(response.queues).keys()));

                // hide fullySynced by default as there might be a lot of them
                let fullySyncedIdx = defaultEnabledStates.indexOf('fullySynced');
                if (fullySyncedIdx >= 0) {
                    defaultEnabledStates.splice(fullySyncedIdx, 1);
                }
                // same for quorum queues
                let allOnlineIdx = defaultEnabledStates.indexOf('allOnline');
                if (allOnlineIdx >= 0) {
                    defaultEnabledStates.splice(allOnlineIdx, 1);
                }
                setEnabledStates(defaultEnabledStates);
            }
        }).catch(error => {
            console.error(error);
            setError("Could not load broker queues");
        }).finally(() => {
            setLoading(false);
        })
    }, [broker.id, refreshCnt, selectedInstance?.instanceId])

    let copyText = brokerQueues !== undefined ? JSON.stringify(brokerQueues.filter(q => enabledStates.includes(q.state!))) : "";

    const colDefs = [
        {
            id: "type",
            header: "Type",
            cell: (item: RabbitBrokerQueue) => item.type
        },
        {
            id: "name",
            header: "Name",
            cell: (item: RabbitBrokerQueue) => item.name
        },
        {
            id: "state",
            header: "State",
            cell: (item: RabbitBrokerQueue) => item.state,
            sortingField: 'state'
        },
        {
            id: "vhost",
            header: "vhost",
            cell: (item: RabbitBrokerQueue) => item.vhost
        },
        {
            id: "primaryNode",
            header: "Primary Node",
            cell: (item: RabbitBrokerQueue) => item.primaryNode
        },
        {
            id: "",
            header: "Describe",
            cell: (item: RabbitBrokerQueue) => (
                <Button onClick={() => {
                    navigate(`/broker/${broker.id}/queue/${encodeURIComponent(item.vhost!)}/${encodeURIComponent(item.name)}`)
                }}>Describe</Button>
            )
        }
    ];

    const hasQuorumQueues = brokerQueues !== undefined && brokerQueues.find(q => q.type === 'quorum') !== undefined;
    if (hasQuorumQueues) {
        colDefs.push({
            id: "",
            header: "Quorum Status",
            cell: (item: RabbitBrokerQueue) => (
                <Button disabled={item.type !== 'quorum'} onClick={() => {
                    navigate(`/broker/${broker.id}/quorum-status/${encodeURIComponent(item.vhost!)}/${encodeURIComponent(item.name)}`)
                }}>Quorum Status</Button>
            )
        });
    }

    return (<div>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
           
        </div>
        {brokerInstances !== undefined &&
            <div style={{marginBottom: 8}}>
                <TextContent><p>Instance Id:</p></TextContent>
                <Select
                    options={[{ label: 'Any Instance', value: 'any' }, ...brokerInstances.map(i => {
                        return {
                            value: i.instanceId,
                            id: i.instanceId
                        }
                    })]}
                    selectedOption={{
                        value: selectedInstance === undefined ? "any" : selectedInstance.instanceId,
                        label: selectedInstance === undefined ? "Any Instance" : selectedInstance.instanceId
                    }}
                    onChange={(event: any) => {
                        if (event.detail.selectedOption.value === "any") {
                            setSelectedInstance(undefined)
                        } else {
                            setSelectedInstance(brokerInstances.find(i => i.instanceId === event.detail.selectedOption.value))
                        }
                    }}
                />
            </div>
        }
        {error && <div style={{marginTop: 8}}><Flashbar items={
            [{
                "type": "error",
                "dismissible": false,
                "content": error
            }]
        }></Flashbar></div>}
        {!error &&
            <div style={{display: 'flex', flexDirection: 'column', gap: 8}}>
                <Table
                    header={
                        <Header actions={
                            <SpaceBetween size='xs' direction='horizontal'>
                                {!loading && <CopyToClipboardButton copyButtonText='Copy' variant='button' text={copyText} />}
                                <Button disabled={loading} iconName="refresh" onClick={() => {
                                    setRefreshCnt(refreshCnt + 1);
                                }} />
                            </SpaceBetween>
                        } variant="h2" counter={brokerQueues !== undefined ? `(${brokerQueues.length.toFixed()})` : ""}>
                            Broker Queues
                        </Header>
                    }
                    totalItemsCount={brokerQueues !== undefined ? brokerQueues.length : 0}
                    filter={
                        <BrokerQueuesSummary enabledStates={enabledStates} brokerQueues={brokerQueues} enabledTypes={enabledTypes} onStateToggle={(state) => {
                            let newEnabledStates = [...enabledStates];
                            if (enabledStates.indexOf(state) >= 0) {
                                    let idx = enabledStates.indexOf(state);
                                    newEnabledStates.splice(idx, 1);
                            } else {
                                    newEnabledStates.push(state);
                            }
                            setEnabledStates(newEnabledStates);
                        }} onTypeToggle={(type: string) => {
                            let newEnabledTypes: string[] = [...enabledTypes];
                            let queueType = type.replace("Queue Type: ", "");
                            if (enabledTypes.indexOf(queueType) >= 0) {
                                let index: number = enabledTypes.indexOf(queueType);
                                newEnabledTypes.splice(index, 1);
                            } else {
                                newEnabledTypes.push(queueType);
                            }
                            setEnabledTypes(newEnabledTypes);
                        }} />
                    }
                    items={brokerQueues !== undefined ? brokerQueues.filter(q => enabledStates.indexOf(q.state!) >= 0 && enabledTypes.indexOf(q.type!) >= 0) : []}
                    sortingColumn={{
                        sortingField: 'state'
                    }}
                    empty={`No queues matching filter found`}
                    loading={loading}
                    loadingText='Loading broker queues'
                    sortingDescending={true}
                    columnDefinitions={colDefs}
                />
            </div>
        }
    </div>
    );
}

export default BrokerQueues;
