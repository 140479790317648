import { DateTime, Info } from "luxon";
import { capitalize } from "./utils";


const DAYS_OF_WEEK = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const formatMaintenanceWindowDate = (maintenanceWindow: { dayOfWeek: string, timeOfDay: string, timeZone: string }) => {

  const [hour, minute] = maintenanceWindow.timeOfDay.split(':').map(Number);

  const currentDateInLocalTimezone =
    DateTime.fromObject(
      {
        minute: minute,
        hour: hour,
        year: DateTime.now().year,
        day: DateTime.now().day,
        month: DateTime.now().month
      },
      { zone: maintenanceWindow.timeZone }
    );

  const dayOfWeek = currentDateInLocalTimezone.weekday;
  const targetDay = Info.weekdays('long').indexOf(capitalize(maintenanceWindow.dayOfWeek.toLowerCase())) + 1;

  const daysDiff = (targetDay - dayOfWeek + 7) % 7;

  const targetDateInLocalTimezone = currentDateInLocalTimezone.plus({ days: daysDiff });

  const targetDateInUTC = targetDateInLocalTimezone.toUTC();

  return targetDateInUTC.toFormat('cccc, HH:mm ZZZZ');

};

export const getNextMWDate = (brokerId: string, startDate: Date, maintenanceWindow: { dayOfWeek: string, timeOfDay: string, timeZone: string }): Date | null => {

  const targetDay = DAYS_OF_WEEK.indexOf(maintenanceWindow.dayOfWeek.toLowerCase());

  const startUtcDate = convertToUtc(maintenanceWindow.timeOfDay, maintenanceWindow.timeZone, startDate);
  let date = new Date(startUtcDate);

  // Calculate the number of days until the next target day
  let daysToNextTargetDay = (targetDay - date.getUTCDay() + 7) % 7;
  if (daysToNextTargetDay === 0) {
    daysToNextTargetDay = 7;
  }

  date.setUTCDate(date.getUTCDate() + daysToNextTargetDay);

  let weekOfEpoch = getWeekOfEpoch(date);
  let charValue = parseInt(brokerId.charAt(2), 16);

  // Adjust for the 3-week rule
  const targetWeekMod = weekOfEpoch % 3;
  const charValueMod = charValue % 3;
  if (targetWeekMod !== charValueMod) {
    const weeksToAdd = (charValueMod - targetWeekMod + 3) % 3;
    date.setUTCDate(date.getUTCDate() + weeksToAdd * 7);

    if (date.getUTCDay() !== targetDay) {
      date.setUTCDate(date.getUTCDate() - ((date.getUTCDay() - targetDay + 7) % 7));
    }
  }

  return date;

};

const convertToUtc = (timeOfDay: string, timeZone: string, date: Date): Date => {
  const [hour, minute] = timeOfDay.split(':').map(Number);
  const time = DateTime.fromObject({ year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate(), hour, minute },
    { zone: timeZone }
  );

  return new Date(time.toUTC().toISO() as string);
};


const getWeekOfEpoch = (date: Date): number => {
  const epoch = DateTime.fromISO('1970-01-01T00:00:00Z', { zone: 'utc' });
  const currentDate = DateTime.fromJSDate(date, { zone: 'utc' });

  const daysSinceEpoch = currentDate.diff(epoch, 'days').days;
  return Math.floor(daysSinceEpoch / 7);
};

export const formatDateToString = (date: Date | null): string => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC',
    hour12: true
  };

  const formattedDate = date ? date.toLocaleDateString('en-US', options) : null;
  return `${formattedDate} UTC`;
};

export const parseMaintenanceWindowStartTime = (timeString: string) => {
  const cleanedString = timeString.replace(/MaintenanceWindowStartTime\{|\}/g, '').trim();
  const params = cleanedString.split(',').map(param => param.trim().split('='));
  const dayOfWeek = params.find(param => param[0] === 'dayOfWeek')?.[1];
  const timeOfDay = params.find(param => param[0] === 'timeOfDay')?.[1];
  const timeZone = params.find(param => param[0] === 'timeZone')?.[1];

  if (dayOfWeek && timeOfDay && timeZone) {
    return {
      dayOfWeek,
      timeOfDay,
      timeZone,
    };
  }
  return null;
};
