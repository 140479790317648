import React, { memo, useState } from 'react';
import CloudWatchDashboard from './CloudWatchDashboard';
import { Checkbox } from '@amzn/awsui-components-react/polaris';
import { getActiveBrokerMetrics } from './metrics/activeBrokerMetrics';
import { getRabbitBrokerMetrics } from './metrics/rabbitBrokerMetrics';
import { Broker, BrokerEngineType, EC2Instance } from '@amzn/amazonmq-opsconsole-client';

type Props = {
    broker: Broker,
    brokerInstances: EC2Instance[]
}

const BrokerMetricsTab : React.FC<Props> = ({broker, brokerInstances}) => {
    const [isExtendedPeriod, setIsExtendedPeriod] = useState(false);
    let widgets;
    if (broker.summary.brokerEngineType === BrokerEngineType.ACTIVEMQ) {
        widgets = getActiveBrokerMetrics(broker);
    } else if (broker.summary.brokerEngineType === BrokerEngineType.RABBITMQ) {
        widgets = getRabbitBrokerMetrics(broker, brokerInstances, isExtendedPeriod);
    } else {
        throw new Error("Unknown engine type: " + broker.summary.brokerEngineType);
    }

    return (
        <div>
            <div style={{display: 'flex', flexWrap: 'wrap', gap: 8, alignItems: 'center', alignContent: 'center', padding: 8}}>
                <div key="Enable Extended Period Checkbox">
                    <Checkbox 
                        className="isExtendedPeriodEnabled"
                        checked={isExtendedPeriod}
                        onChange={(event: any) => {
                            setIsExtendedPeriod(event.detail.checked);  // Update React state
                        }}
                    >Change Period to 5 minutes</Checkbox>
                </div>   
            </div>
            <CloudWatchDashboard
                broker={broker}
                layoutType={broker.summary.brokerEngineType === BrokerEngineType.RABBITMQ ? 'manual' : 'automatic'}
                serviceAccountId={broker.summary.serviceAccountId}
                widgets={widgets}
                widgetsPerRow={2}
                periodOverride={broker.summary.brokerEngineType === BrokerEngineType.ACTIVEMQ ? 'auto' : 'inherit'}
            /> 
        </div>
    )
}

export default memo(BrokerMetricsTab);