export const CLOUDWATCH_DASHBOARDS = 'CloudWatchDashboards';

// Events fired by the iframe
export const EVENT_TYPE_MESSAGE = 'message';
export const EVENT_DASHBOARD_READY = 'ready';
export const EVENT_DASHBOARDS_LISTED = 'dashboardsListed';
export const EVENT_DASHBOARDS_LIST_FAILED = 'dashboardsListFailed';
export const EVENT_CONSOLE_HASH_CHANGED = 'consoleHashChanged';

// Actions sent to the iframe
export const ACTION_LOAD_CUSTOM_DASHBOARD = 'loadCustomDashboard';
export const ACTION_LOAD_DASHBOARD = 'loadDashboard';
