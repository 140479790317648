import React, { useEffect, useState } from 'react';
import { Container, Flashbar, Header, Spinner } from  '@amzn/awsui-components-react/polaris';
import JSONPretty from 'react-json-pretty';
import { getRabbitBrokerPolicies } from '../../../api/api';
import CopyToClipboardButton from '../../Components/copyToClipboardButton';
import { Broker } from '@amzn/amazonmq-opsconsole-client';
import { RabbitBrokerPolicies } from '../../../types';

type Props = {
    broker: Broker,
}

const BrokerPolicies: React.FC<Props> = ({broker}) => {
    const [brokerPolicies, setBrokerPolicies] = useState<RabbitBrokerPolicies | undefined>(undefined);
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        setError("");
        getRabbitBrokerPolicies(broker.id).then(response => {
            setBrokerPolicies(response.brokerPolicies as RabbitBrokerPolicies);
        }).catch(error => {
            console.error(error);
            setError("Could not load broker policies");
        }).finally(() => {
            setLoading(false);
        });
    
    }, [broker.id]);

    return (
        <div>
            {error && <Flashbar items={
                [{
                    "type": "error",
                    "dismissible": false,
                    "content": error
                }]
            }></Flashbar>}
                <Container header={
                    <Header variant='h2'>
                        Policies
                    </Header>
                }>
                     {loading && <Spinner /> }
                     {!loading && !error && brokerPolicies &&
                        <>
                            <CopyToClipboardButton text={JSON.stringify(brokerPolicies)} />
                            <JSONPretty data={brokerPolicies}></JSONPretty>
                        </>
                     }
                </Container>
        </div>
    );
}

export default BrokerPolicies;