import { Flashbar, Spinner, Tabs } from '@amzn/awsui-components-react/polaris';
import React, { useEffect, useState } from 'react';
import { getCloudwatchDashboardLink } from '../../../api/api';
import CloudWatchDashboard from '../../Components/cloudWatchDashboard';
import { Broker } from '@amzn/amazonmq-opsconsole-client';

type Props = {
    broker: Broker,
}

const ServiceMetricsOld : React.FC<Props> = ({broker}) => {
    
    const [dashboards, setDashboards] = useState<any>(null);
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string | undefined>(undefined);

    useEffect(() => {
        setLoading(true);
        getCloudwatchDashboardLink(broker.id, broker.snapshotId).then(response => {
            setDashboards(response.link);
        }).catch(error => {
            console.error(error);
            setError("Could not load Cloudwatch Dashboards");
        }).finally(() => {
            setLoading(false);
        })
    }, [broker.id, broker.snapshotId])

    if (loading) {
        return <Spinner />
    }
    
    if (error) {
        return <Flashbar items={
            [{
                "type": "error",
                "dismissible": false,
                "content": error
            }]
        }></Flashbar>
    }

    if (dashboards === null) {
        return <div></div>
    }

    let tabs: any[] = []

    Object.keys(dashboards).forEach(function (title) {
        let dashboardModel = dashboards[title];
        tabs.push({
            label: title,
            id: title,
            content:
                <CloudWatchDashboard
                    title={title}
                    broker={broker}
                    model={dashboardModel}
                    region={broker.region}
                    serviceAccountId={broker.summary.serviceAccountId}
                />
        });
    });
    
    return <Tabs
                tabs={tabs}
                variant="container"
                activeTabId={activeTab === undefined ? Object.keys(dashboards)[0] : activeTab}
                onChange={(newTab) => {
                    setActiveTab(newTab.detail.activeTabId);
                }}
            />;

}

export default ServiceMetricsOld;