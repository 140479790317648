import { Multiselect } from '@amzn/awsui-components-react';
import React from 'react';
import { LogSource } from '../../../types';

type Props = {
    logSources: LogSource[],
    selectedLogSources: LogSource[],
    onLogSourcesSelected: (logSources: LogSource[]) => void
}

const LogSourceSelector : React.FC<Props> = ({logSources, selectedLogSources, onLogSourcesSelected}) => {
    return (
        <Multiselect
            selectedOptions={selectedLogSources.map(ls => { return { value: ls.logGroupName + "_" + ls.logStreamName, label: ls.label }})}
            keepOpen={false}
            onChange={({detail}) => {
                onLogSourcesSelected(detail.selectedOptions.map(option => logSources.find(ls => ls.label === option.label)!));
            }}
            options={logSources.map(logSource => {
                return {
                    label: logSource.label,
                    value: logSource.logGroupName + "_" + logSource.logStreamName,
                }
            })}
            placeholder="Select log streams"
        />
    )
}

export default LogSourceSelector;