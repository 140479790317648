import DispatcherEvent from './DispatcherEvent';
import { isFunction, validate } from './tools';

class Dispatcher {

    constructor() {
        this.events = {};
    }

    dispatch(eventName, data) {
        const event = this.events[eventName];
        if (event) {
            event.fire(data);
        }
    }

    on(eventName, callback) {
        validate(isFunction(callback), 'Callback has to be a function');

        let event = this.events[eventName];
        if (!event) {
            event = new DispatcherEvent(eventName);
            this.events[eventName] = event;
        }
        event.registerCallback(callback);
    }

    off(eventName, callback) {
        validate(isFunction(callback), 'Callback has to be a function');

        const event = this.events[eventName];
        if (event && event.callbacks.indexOf(callback) > -1) {
            event.unregisterCallback(callback);

            // No callbacks, remove event from dispatcher
            if (event.callbacks.length === 0) {
                delete this.events[eventName];
            }
        }
    }

    release() {
        this.events = {};
    }
}

export default Dispatcher;