import { Broker } from "@amzn/amazonmq-opsconsole-client"
import React, { memo } from "react";
import { getRabbitNlbMetrics } from "./metrics/rabbitNlbMetrics";
import CloudWatchDashboard from "./CloudWatchDashboard";

type Props = {
    broker: Broker
}

const RabbitNlbMetricsTab : React.FC<Props> = ({broker}) => {
    let widgets = getRabbitNlbMetrics(broker);

    return (
        <div>
            <CloudWatchDashboard
                broker={broker}
                layoutType='manual'
                widgetsPerRow={2}
                serviceAccountId={broker.summary.serviceAccountId}
                widgets={widgets}
            />
        </div>
    )
}

export default memo(RabbitNlbMetricsTab);

