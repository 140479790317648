import { ColumnLayout, Container, Header, Link } from '@amzn/awsui-components-react/polaris';
import React from 'react';
import CopyToClipboardButton from '../../Components/copyToClipboardButton';
import { renderField } from './brokerInstanceInfoTable';
import { Broker, BrokerConfigEntry, BrokerMaintenanceInfo } from '@amzn/amazonmq-opsconsole-client';


function renderConfiguration(attributeName: string, configEntry: BrokerConfigEntry) {

    if (!configEntry) {
        return (
            <div key={attributeName}>
                <div className="awsui-util-label">
                    {attributeName}
                </div>
                <div className="awsui-util-label">None</div>
            </div>
        )
    }

    let linkTitle = configEntry.configurationId + " | " + configEntry.revision;
    let isConfigInvalid = configEntry.invalid !== null ? configEntry.invalid : false;
    if (isConfigInvalid) {
        linkTitle = linkTitle + " - invalid";
    } else {
        linkTitle = linkTitle + " - ✔";
    }
    return (
        <div key={attributeName}>
            <div className="awsui-util-label">
                {attributeName}
                {configEntry &&
                    <CopyToClipboardButton text={configEntry.isenLink} textDescription={attributeName} />
                }
            </div>
            <div className="awsui-util-label">
                <Link target="_blank" href={configEntry.isenLink}>{linkTitle}</Link>
                <CopyToClipboardButton text={configEntry.isenLink}  />
            </div>
        </div>
    )
}

type Props = {
    brokerMaintenanceInfo: BrokerMaintenanceInfo
    broker: Broker
}

const BrokerMaintenanceInfoTable : React.FC<Props> = ({brokerMaintenanceInfo, broker}) => {
    let content : React.ReactElement[] = [];
    content.push(renderConfiguration("Current Configuration", brokerMaintenanceInfo.currentConfiguration));
    content.push(renderConfiguration("Pending Configuration", brokerMaintenanceInfo.pendingConfiguration));
    content.push(renderField("Next Maintenance", brokerMaintenanceInfo.nextMaintenance));
    content.push(renderField("Maintenance Flag", brokerMaintenanceInfo.maintenanceFlag ? "true" : "false"));
    content.push(renderField("Auto Minor Version Upgrade", brokerMaintenanceInfo.autoMinorVersionUpgrade ? "true" : "false"));
    return (
        <Container header={
            <Header variant='h2'>
                Broker Maintenance Info
            </Header>
        }>
            <ColumnLayout columns={3} variant="text-grid">
                {content}
            </ColumnLayout>
        </Container>
    )
}


export default BrokerMaintenanceInfoTable;
