import {
    ACTION_LOAD_CUSTOM_DASHBOARD,
    ACTION_LOAD_DASHBOARD,
    EVENT_DASHBOARD_READY,
    EVENT_DASHBOARDS_LIST_FAILED,
    EVENT_DASHBOARDS_LISTED,
} from './constants';
import {
    calculateDashboardHeight,
    DEFAULT_CHART_HEIGHT_UNITS,
    generateModel,
    getMaxYFromModel
} from './utils';
import EmbeddableView from "./EmbeddableView";

const DEFAULT_STYLE = {
    border: 0,
    visibility: 'hidden',
    width: '100%'
};

const DEFAULT_CONFIG = {
    displayMode: 'static',
    heightUnit: 40,
    widgetMarginX: 10,
    widgetMarginY: 10,
    embeddedMaximize: false
};

const DEFAULT_LAYOUT_OPTIONS = {
    widgetHeightInUnits: DEFAULT_CHART_HEIGHT_UNITS,
    widgetsPerRow: 3
};

export const Events = {
    DASHBOARD_READY_EVENT: 'ready',
    WIDGET_MAXIMIZED_EVENT: 'widgetMaximized'
};

class Dashboard extends EmbeddableView {
    /**
     *
     * @param parent - DOM element to attach the embedded dashboard to.
     * @param name - unique name of the embedded dashboard.
     * @param region - region in which the embedded dashboard has to be accessed.
     * @param dashboardConfig - configuration for the embedded dashboard (see https://code.amazon.com/packages/CloudWatchDashboards-Inside/blobs/mainline/--/README.md for details on the different properties).
     * @param style - custom CSS rules to apply to the embedded dashboard.
     * @param title - accessible contextual title to provide to the iframe.
     */
    constructor(parent, name, region, dashboardConfig, style, title, partition) {
        super(parent,
            name,
            region,
            Object.assign({}, DEFAULT_CONFIG, dashboardConfig),
            Object.assign({}, DEFAULT_STYLE, style),
            title,
            partition
        );
    }

    iframeUrlTarget() {
        return this.config.internal ? 'dashboardInternal' : 'dashboard';
    }

    load(dashboardName) {
        const task = {
            action: ACTION_LOAD_DASHBOARD,
            args: {
                dashboardName
            }
        };

        this.taskExecutor.enqueue(task);
    }

    update(dashboardModel, layoutOptions) {
        let modelMaxY;
        if (layoutOptions) {
            // Auto-generating layout, calculating new dashboard model
            const options = Object.assign({}, DEFAULT_LAYOUT_OPTIONS, layoutOptions);
            const { model, metaData } = generateModel(dashboardModel, options.widgetHeightInUnits, options.widgetsPerRow);

            modelMaxY = metaData.maxY + layoutOptions.widgetHeightInUnits;
            dashboardModel = model;
        } else {
            modelMaxY = getMaxYFromModel(dashboardModel);
        }
        const height = calculateDashboardHeight(modelMaxY, this.config.heightUnit, this.config.widgetMarginY, this.config.style);
        this.updateStyle({
            height: `${height}px`
        });

        const task = {
            action: ACTION_LOAD_CUSTOM_DASHBOARD,
            args: {
                dashboardBody: dashboardModel
            }
        };

        this.taskExecutor.enqueue(task);
    }

    handleCloudWatchDashboardsMessage(message) {
        if ([EVENT_DASHBOARDS_LISTED, EVENT_DASHBOARDS_LIST_FAILED, EVENT_DASHBOARD_READY].indexOf(message.event) >= 0) {
            const readyStateChanged = this.taskExecutor.ready();

            // Handles ready message
            if (readyStateChanged) {
                const self = this;

                // Deferring displaying of dashboard so dashboard list page won't be visible
                setTimeout(() => {
                    self.updateStyle({
                        visibility: 'visible'
                    });

                    // Sending dashboard ready event to subscribers
                    self.dispatcher.dispatch(Events.DASHBOARD_READY_EVENT);
                }, 0);
            }
        }

        // Handles widgetMaximized message
        if (message.event === Events.WIDGET_MAXIMIZED_EVENT) {
            // Sending dashboard maximized event to subscribers
            this.dispatcher.dispatch(Events.WIDGET_MAXIMIZED_EVENT);
        }
    }
}

export default Dashboard;
