import { SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import FailedWorkflowsTable from './FailedWorkflowsTable';
import StuckWorkflowsTable from './StuckWorkflowsTable';

const RegionDashboard : React.FC = () => {
    
    return (
        <SpaceBetween direction='vertical' size='xs'>
            <FailedWorkflowsTable />
            <StuckWorkflowsTable />
        </SpaceBetween>
    )
}

export default RegionDashboard;