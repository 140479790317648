import React, { useEffect, useState } from 'react';
import { getBrokerWorkflows } from '../../../api/api';
import WorkflowsTable from '../../../Components/WorkflowsTable/WorkflowsTable';
import { Broker, Workflow } from '@amzn/amazonmq-opsconsole-client';

type Props = {
    broker: Broker,
}  

const BrokerWorkflows : React.FC<Props> = ({broker}) =>  {

    const [workflows, setWorkflows] = useState<Workflow[]>([]);
    const [loadingData, setLoadingData] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
   
    const [refreshCnt, setRefreshCnt] = useState<number>(0);
   

    useEffect(() => {
        setLoadingData(true);
        setError("");
        getBrokerWorkflows(broker.id).then(response => {
            setWorkflows(response.workflows);
        }).catch(error => {
            setError("Could not load workflows.");
            console.error(error);
        }).finally(() => {
            setLoadingData(false)
        })
    }, [refreshCnt, broker.id]);

    return (
        <WorkflowsTable
            workflows={workflows}
            loading={loadingData}
            title='Broker Workflows'
            error={error}
            columns={["workflowName", "type", "maintenance", "status", "startTime", "endTime"]}
            onRefresh={() => {
                setRefreshCnt(refreshCnt + 1)
            }}
        />
    )

}

export default BrokerWorkflows;
