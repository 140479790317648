import { Broker, EC2Instance, RabbitBrokerVhost } from '@amzn/amazonmq-opsconsole-client';
import { Button, Flashbar, Header, Select, SpaceBetween, StatusIndicator, Table, TextContent } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { BrokerInstance } from '../../../types';
import { getRabbitVhosts } from '../../../api/api';

type Props = {
    broker: Broker,
    brokerInstances: EC2Instance[] | undefined
}

const BrokerVhosts : React.FC<Props> = ({broker, brokerInstances}) => {


    const [selectedInstance, setSelectedInstance] = useState<BrokerInstance | undefined>(undefined);
    const [brokerVhosts, setBrokerVhosts] = useState<RabbitBrokerVhost[] | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [refreshCnt, setRefreshCnt] = useState<number>(0);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        if (brokerInstances === undefined) {
            setSelectedInstance(undefined);
        } else {
            setSelectedInstance(brokerInstances.length === 0 ? undefined : brokerInstances[0]);
        }
    }, [brokerInstances]);


    useEffect(() => {
        if (selectedInstance === undefined) {
            return;
        }
        setLoading(true);
        setError("");
        getRabbitVhosts(broker.id, selectedInstance?.instanceId).then(response => {
            setBrokerVhosts(response.vhosts);
        }).catch(error => {
            console.error(error);
            setError("Could not load broker vhosts");
        }).finally(() => {
            setLoading(false);
        })
    }, [broker.id, refreshCnt, selectedInstance?.instanceId])

    return (
        <SpaceBetween direction='vertical' size='xs'>
            {brokerInstances !== undefined &&
                <div style={{marginBottom: 8}}>
                    <TextContent><p>Instance Id:</p></TextContent>
                    <Select
                        options={[...brokerInstances.map(i => {
                            return {
                                value: i.instanceId,
                                id: i.instanceId
                            }
                        })]}
                        selectedOption={{
                            value: selectedInstance?.instanceId,
                            label: selectedInstance?.instanceId
                        }}
                        onChange={(event: any) => {
                            if (event.detail.selectedOption.value === "any") {
                                setSelectedInstance(undefined)
                            } else {
                                setSelectedInstance(brokerInstances.find(i => i.instanceId === event.detail.selectedOption.value))
                            }
                        }}
                    />
                </div>
            }
            {error && <div style={{marginTop: 8}}><Flashbar items={
                [{
                    "type": "error",
                    "dismissible": false,
                    "content": error
                }]
            }></Flashbar></div>}
            {!error &&
                <Table
                    header={
                        <Header actions={
                            <SpaceBetween size='xs' direction='horizontal'>
                                <Button disabled={loading} iconName="refresh" onClick={() => {
                                    setRefreshCnt(refreshCnt + 1);
                                }} />
                            </SpaceBetween>
                        } variant="h2">
                            Broker Vhosts
                        </Header>
                    }
                    items={brokerVhosts !== undefined ? brokerVhosts : []}
                    empty={loading ? '' : `No vhosts found`}
                    loading={loading}
                    loadingText='Loading broker vhosts'
                    columnDefinitions={[
                        {
                            id: "name",
                            header: "Name",
                            cell: (item: RabbitBrokerVhost) => item.name
                        },
                        {
                            id: "description",
                            header: "Description",
                            cell: (item: RabbitBrokerVhost) => item.description
                        },
                        {
                            id: "defaultQueueType",
                            header: "Default Queue Type",
                            cell: (item: RabbitBrokerVhost) => item.defaultQueueType
                        },
                        {
                            id: "state",
                            header: "Cluster State",
                            cell: (item: RabbitBrokerVhost) => {
                                return (
                                    <SpaceBetween direction='vertical' size='xs'>
                                        {Object.entries(item.clusterState).map(([key, value]) => {
                                            if (value !== 'running') {
                                                return <div>{key} - {value} <StatusIndicator type='warning'></StatusIndicator></div>
                                            }
                                            return (<div>{key} - {value}</div>)
                                        })}
                                    </SpaceBetween>
                                )
                            }
                        },
                    ]}
                />
            }
        </SpaceBetween>
    )
}

export default BrokerVhosts;