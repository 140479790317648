import React, { useEffect, useState } from 'react';
import {
    Tabs
} from '@amzn/awsui-components-react/polaris';
import { getLogStreams } from '../../../api/api';
import LogsTable from './LogsTable';
import PerInstanceLogs from './perInstanceLogs';
import { Broker, LogStream } from '@amzn/amazonmq-opsconsole-client';

type Props = {
    broker: Broker,
}

const Logs : React.FC<Props> = ({broker}) => {
    
    const [loadingStreamData, setLoadingStreamData] = useState<boolean>(false);
    const [logStreams, setLogStreams] = useState<LogStream[]>([]);

    useEffect(() => {
        setLoadingStreamData(true);
        getLogStreams(broker.id, broker.snapshotId).then(response => {
            setLogStreams(response.logStreams);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setLoadingStreamData(false);
        })
    }, [broker.id, broker.snapshotId]);

    const tabs = [
        {
            label: 'Log Streams',
            id: 'logStreams',
            content: <LogsTable
                broker={broker}
                logStreamNames={logStreams}
                loadingStreamNames={loadingStreamData}
            />
        },
        {
            label: 'Per-Instance Logs',
            id: 'perInstanceLogs',
            content: <PerInstanceLogs
                brokerId={broker.id}
                snapshotId={broker.snapshotId}
                region={broker.region}
                logStreamNames={logStreams}
                loadingStreamData={loadingStreamData}
            />
        }
    ];

    return (
        <Tabs tabs={tabs} />
    )

}

export default Logs;
