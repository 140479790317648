
export const SECTIONS: any = {
    'queue_procs'         : ['queue',  'Classic queues (masters)'],
    'queue_slave_procs'   : ['queue',  'Classic queues (mirrors)'],
    'quorum_queue_procs'  : ['quorum', 'Quorum queues'],
    'stream_queue_procs'          : ['stream',  'Stream queues'],
    'stream_queue_replica_reader_procs'  : ['stream',  'Stream queues (replica reader)'],
    'stream_queue_coordinator_procs'  : ['stream',  'Stream queues (coordinator)'],
    'binary'              : ['binary', 'Binaries'],
    'connection_readers'  : ['conn',   'Connection readers'],
    'connection_writers'  : ['conn',   'Connection writers'],
    'connection_channels' : ['conn',   'Connection channels'],
    'connection_other'    : ['conn',   'Connections (other)'],
    'mnesia'              : ['table',  'Mnesia'],
    'msg_index'           : ['table',  'Message store index'],
    'mgmt_db'             : ['table',  'Management database'],
    'quorum_ets'          : ['table',  'Quorum queue ETS tables'],
    'other_ets'           : ['table',  'Other ETS tables'],
    'plugins'             : ['proc',   'Plugins'],
    'other_proc'          : ['proc',   'Other process memory'],
    'code'                : ['system', 'Code'],
    'atom'                : ['system', 'Atoms'],
    'other_system'        : ['system', 'Other system'],
    'allocated_unused'    : ['unused', 'Allocated unused'],
    'reserved_unallocated': ['unused', 'Unallocated reserved by the OS']
}