import { Broker, CodeDeployment, DeploymentStatus } from '@amzn/amazonmq-opsconsole-client';
import React, { useEffect, useState } from 'react';
import { getCodeDeployments } from '../../../api/api';
import { Button, Header, Link, SpaceBetween, Spinner, StatusIndicator, Table } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { isoFormat } from 'd3-time-format';
import { createIsengardLink } from '../../../utils';
import useConfig from '../../../hooks/useConfig';

type Props = {
    broker: Broker
}

const CodeDeploy : React.FC<Props> = ({broker}) => {

    const [codeDeployments, setCodeDeployments] = useState<CodeDeployment[]>([]);
    const [error, setError] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const config = useConfig();
    const [refreshCnt, setRefreshCnt] = useState<number>(0);


    useEffect(() => {
        setError("");
        setLoading(true);
        getCodeDeployments(broker.id).then(response => {
            setCodeDeployments(response.codeDeployments);
        }).catch(error => {
            console.error(error);
            setError("Could not load broker code deployments");
        }).finally(() => {
            setLoading(false);
        })
    }, [broker.id, refreshCnt]);

    const {
        items,
        collectionProps,
      } = useCollection(codeDeployments ?? [], {
        sorting: {
          defaultState: {
            sortingColumn: {
                sortingField: 'startTime'
            },
            isDescending: true,
          },
        },
      });

    function isFailed(status: DeploymentStatus) {
        return ([DeploymentStatus.Failed,
            ] as DeploymentStatus[]).includes(status);
    }

    function isSucceeded(status: DeploymentStatus) {
        return ([DeploymentStatus.Succeeded, 
            DeploymentStatus.Ready] as DeploymentStatus[]).includes(status);
    }

    function isInProgress(status: DeploymentStatus) {
        return ([DeploymentStatus.InProgress, 
                DeploymentStatus.Queued,
                DeploymentStatus.Baking] as DeploymentStatus[]).includes(status);
    }

    function renderCodeDeploymentStatus(status: DeploymentStatus) {
        if (isFailed(status)) {
            return <StatusIndicator type='error'>{status}</StatusIndicator>;
        } else if (isSucceeded(status)) {
            return <StatusIndicator type='success'>{status}</StatusIndicator>;
        } else if (isInProgress(status)) {
            return <StatusIndicator type='in-progress'>{status}</StatusIndicator>;
        } else {
            return <StatusIndicator type='warning'>{status}</StatusIndicator>;
        }
    }

    if (!config) {
        return <Spinner />
    }

    let columnDefinitions = [
        {
            id: "codeDeployApplicationName",
            header: "App Name",
            cell: (item: CodeDeployment) => <Link target='_blank' href={createIsengardLink(config.isengardEndpoint, broker.brokerInfo.cellAccountId, 'ReadOnly', `codesuite/codedeploy/applications/${item.codeDeployApplicationName}?region=${config.region}`)}>
                {item.codeDeployApplicationName}
            </Link>
        },
        {
            id: "codeDeployDeploymentGroup",
            header: "Deployment Group",
            cell: (item: CodeDeployment) => <Link target='_blank' href={createIsengardLink(config.isengardEndpoint, broker.brokerInfo.cellAccountId, 'ReadOnly', `codesuite/codedeploy/applications/${item.codeDeployApplicationName}/deployment-groups/${item.codeDeployDeploymentGroup}?region=${config.region}`)}>
                {item.codeDeployDeploymentGroup}
            </Link>
        },
        {
            id: "deploymentId",
            header: "Deployment ID",
            cell: (item: CodeDeployment) => <Link target='_blank'
                 href={createIsengardLink(config.isengardEndpoint, broker.brokerInfo.cellAccountId, 'ReadOnly', `codesuite/codedeploy/deployments/${item.deploymentId}?region=${config.region}`)}>
             {item.deploymentId}
            </Link>
        },
        {
            id: "executionStatus",
            header: "Status",
            width: 140,
            sortingField: 'executionStatus',
            cell: (item: CodeDeployment)  => renderCodeDeploymentStatus(item.status)
        },
        {
            id: "startTime",
            header: "Start Time",
            sortingField: "startTime",
            cell: (item: CodeDeployment)  => item.startTime ? isoFormat(new Date(item.startTime)) : ""
        },
        {
            id: "endTime",
            header: "End Time",
            sortingField: "endTime",
            cell: (item: CodeDeployment)  => item.endTime ? isoFormat(new Date(item.endTime)) : ""
        }
    ];
    
    function onRefresh() {
        setRefreshCnt(refreshCnt + 1);
    }

    return (
        <div>
            <Table
                {...collectionProps}
                items={items}
                columnDefinitions={columnDefinitions}
                empty={!error ? "No Code Deploy executions found" : ""}
                wrapLines={true}
                loading={loading}
                header={
                    <Header
                        description={<SpaceBetween direction='vertical' size='xs'>
                            {error && <StatusIndicator type='error'>{error}</StatusIndicator> }
                            </SpaceBetween>}
                        actions={
                            <>
                                <Button disabled={loading} iconName="refresh" onClick={() => {
                                    onRefresh();
                                }} />
                            </>
                        } 
                    >Broker Code Deployments</Header>
                }
            />
        </div>
    )
}

export default CodeDeploy;