import { KeyValuePairs, Link, Modal, Spinner, StatusIndicator, Table } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { getInstanceImage } from '../../../api/api';
import CopyToClipboardButton from '../../Components/copyToClipboardButton';
import { InstanceImage, Broker } from '@amzn/amazonmq-opsconsole-client';
import { isoFormat } from 'd3-time-format';
import { createIsengardLink } from '../../../utils';
import useConfig from '../../../hooks/useConfig';

type Props = {
    broker: Broker,
    amiId: string
}

const AmiLink : React.FC<Props> = ({broker, amiId}) => {
    const [amiLoading, setAmiLoading] = useState<boolean>(false);
    const config = useConfig();
    const [instanceImage, setInstanceImage] = useState<InstanceImage | undefined>(undefined);
    const [amiDialogOpen, setAmiDialogOpen] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    if (config === undefined) {
        return <></>
    }

    return (
        <>
            <Modal
                visible={amiDialogOpen}
                onDismiss={() => setAmiDialogOpen(false)}
                header={`AMI ${amiId}`}
            >
                {amiLoading && <Spinner />}
                {error && <StatusIndicator type='error'>{error}</StatusIndicator>}
                {!amiLoading && !error && instanceImage &&
                    <KeyValuePairs
                        columns={3}
                        items={[
                            {
                                label: 'Image Id',
                                value: <Link target="_blank" href={createIsengardLink(
                                                    config.isengardEndpoint,
                                                    broker.brokerInfo.cellAccountId,
                                                    'ReadOnly',
                                                    `ec2/home?region=${broker.regionName}#ImageDetails:imageId=${instanceImage.imageId}`
                                                    )}>{instanceImage.imageId}</Link>,
                            },
                            {
                                label: 'Image Name',
                                value: instanceImage.imageName,
                            },
                            {
                                label: 'Architecture',
                                value: instanceImage.architecture,
                            },
                            {
                                label: 'Description',
                                value: instanceImage.description,
                            },
                            {
                                label: 'Location',
                                value: instanceImage.imageLocation,
                            },
                            {
                                label: 'Creation Date',
                                value: isoFormat(new Date(instanceImage.creationDate)),
                            },
                        ]}
                    />
                }
            </Modal>
            <div className="awsui-util-label" style={{margin: 0}}>
                AMI:
                <Link onClick={() => {
                    setAmiLoading(true);
                    setAmiDialogOpen(true);
                    getInstanceImage(broker.id, amiId).then(response => {
                        setInstanceImage(response.image);
                    }).catch(error => {
                        console.log(error);
                        setError("Could not load AMI " + amiId);
                    }).finally(() => {
                        setAmiLoading(false);
                    })
                }}  >&nbsp;{amiId}</Link>
                <CopyToClipboardButton text={amiId}/>
            </div>
        </>
    )
}

export default AmiLink;