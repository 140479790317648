import { 
    MidwayIdentityCredentialProviderSTS,  
    MidwayIdentityCredentialSTS,
  } from '@amzn/midway-identity-credential-provider';
import { AppConfig } from './types';
import { AmazonMqOpsConsoleClient, AmazonMqOpsConsoleClientConfig } from '@amzn/amazonmq-opsconsole-client';
import { isLocalhost } from '../utils';

let credentialProvider: MidwayIdentityCredentialProviderSTS;
let awsCredentials: MidwayIdentityCredentialSTS;
export let username: string;
  
export const fetchTemporarySigV4Credentials = async (config: AppConfig): Promise<MidwayIdentityCredentialSTS> => {
   
    if (credentialProvider === undefined) {
        credentialProvider = new MidwayIdentityCredentialProviderSTS(
            config.iamRoleArn, 
            config.clientId,
            new URL(`https://${config.redirectUri}`),
            config.partition,
            {
                maxAttempts: 3,
                region: config.region
            },
        );
    }

    awsCredentials = await credentialProvider.getCredentials();

    return awsCredentials;
}

let configJson : AppConfig | undefined = undefined;
let apiClient : AmazonMqOpsConsoleClient | undefined = undefined;

export async function getClient() {
    if (!configJson) {
        const fullHostname = window.location.protocol + '//' + window.location.host;
        const config = await fetch(`${fullHostname}/config.json`)

        configJson = await config.json() as AppConfig;
        if (isLocalhost()) {
            configJson.clientId = 'localhost.a2z.com:3000';
            configJson.redirectUri = 'localhost.a2z.com:3000';
        }
    }
    const awsCredentials = await fetchTemporarySigV4Credentials(configJson);
    if (apiClient === undefined || (await isClientNeedsRenewal(apiClient, awsCredentials))) {
        
        const clientConfig: AmazonMqOpsConsoleClientConfig = {
            endpoint: getApiEndpoint(),
            region: configJson.region,
            credentials: {
                sessionToken: awsCredentials.SessionToken,
                accessKeyId: awsCredentials.AccessKeyId!,
                secretAccessKey: awsCredentials.SecretAccessKey!,
            },
            signingName: 'execute-api',
            maxAttempts: 1
        }

        apiClient = new AmazonMqOpsConsoleClient(clientConfig);
        username = awsCredentials.MidwayUser;
    }
    return apiClient;
}

const isClientNeedsRenewal = async (client: AmazonMqOpsConsoleClient, newCredentials: MidwayIdentityCredentialSTS): Promise<boolean> => {
    let currentCreds = await client.config.credentials();
    return currentCreds.sessionToken !== newCredentials.SessionToken;
}

function getApiEndpoint() {
    if (isLocalhost()) {
        return `https://api.opsconsole.${process.env.REACT_APP_USER}.people.aws.dev`;
    } else {
        return `https://api.${window.location.hostname}`
    }
}