export const isObject = (obj) => Object.prototype.toString.call(obj) === '[object Object]';
export const isFunction = (fn) =>  Object.prototype.toString.call(fn) === '[object Function]';

export const validate = (condition, errorMessage) => {
    if (!condition) { throw new Error(errorMessage);}
};

export const generateQueryString = obj => {
    if (!isObject(obj)) { return '';}

    return Object.keys(obj)
        .reduce((agg, key) => {
            // Only pass keys with value
            if (obj[key] !== undefined) {
                const encodedKey = encodeURIComponent(key);

                const stringValue = isObject(obj[key]) ? JSON.stringify(obj[key]) : obj[key];
                const encodedValue = encodeURIComponent(stringValue);

                agg.push(`${encodedKey}=${encodedValue}`);
            }
            return agg;
        }, [])
        .join('&');
};

// Taken from https://davidwalsh.name/function-debounce
//
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export const debounce = (func, wait, immediate) => {
    let timeout;
    return function () {
        const context = this,
            args = arguments;

        const later = () => {
            timeout = null;
            if (!immediate) { func.apply(context, args); }
        };

        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);

        if (callNow) {
            func.apply(context, args);
        }
    };
};
