import { useState, useEffect } from "react";
import { AppConfig } from "../api/types";

const useConfig = () => {
  const [config, setConfig] = useState<AppConfig | undefined>(undefined);

  useEffect(() => {
    (async function() {
        const fullHostname = window.location.protocol + '//' + window.location.host;
        const config = await fetch(`${fullHostname}/config.json`)
        const configJson = await config.json() as AppConfig;
        setConfig(configJson);
    })();
  }, []);

  return config;
};

export default useConfig;