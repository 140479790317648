import { BrokerEngineVersion } from "@amzn/amazonmq-opsconsole-client";
import semver, { SemVer } from "semver";

export function getLatestPublicGAVersion(brokerEngineVersions: BrokerEngineVersion[]): string | undefined {
    let currentLatest : SemVer | undefined = undefined;
    for (let i = 0; i < brokerEngineVersions.length; i++) {
        if (!(brokerEngineVersions[i].state === 'PUBLIC_GA')) {
            continue;
        }
        const semver: SemVer = new SemVer(brokerEngineVersions[i].version);
        if (currentLatest === undefined || currentLatest.compare(semver) < 0) {
            currentLatest = semver;
        }
    }
    return currentLatest?.version;
}


export function isEarlierMajorVersion(brokerEngineVerion: string, latestEngineVerison: string): boolean {
    const brokerEngineSemVer = new SemVer(brokerEngineVerion);
    const latestEngineSemVer = new SemVer(latestEngineVerison);

    const brokerEngineVersionExcludingPatch = new SemVer(brokerEngineSemVer.major + "." + brokerEngineSemVer.minor + ".0");
    const latestEngineSemVerExcludingPatch = new SemVer(latestEngineSemVer.major + "." + latestEngineSemVer.minor + ".0");
    
    return brokerEngineVersionExcludingPatch.compare(latestEngineSemVerExcludingPatch) < 0;
}