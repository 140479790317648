import React from 'react';
import { Alert, ColumnLayout, Container, Link, TextContent } from '@amzn/awsui-components-react/polaris';
import CopyToClipboardButton from "../../Components/copyToClipboardButton";
import { BrokerAlarm } from '@amzn/amazonmq-opsconsole-client';

type Props = {
    brokerAlarms: BrokerAlarm[]
}

const BrokerAlarmsTable : React.FC<Props> = ({brokerAlarms}) => {

    let hasAlarms = brokerAlarms.length > 0;

    if (!hasAlarms) {
        return <Container>
                <Alert type='success'>No Broker Alarms</Alert>
        </Container>
    }

    return (
        <Container header={
            <TextContent>
                <Alert type='error'>Broker Alarms</Alert>
            </TextContent>
        }>
            {hasAlarms &&
                <ColumnLayout columns={3} variant="text-grid">
                    {brokerAlarms.map((brokerAlarm) => {
                        let alarmLink = brokerAlarm.link;
                        return (
                            <div key={brokerAlarm.name} style={{display: 'flex', alignItems: 'center', wordWrap: 'break-word', wordBreak: 'break-all'}}>
                                <Link href={alarmLink} target="_blank">
                                    {brokerAlarm.name}
                                </Link>
                                <CopyToClipboardButton text={brokerAlarm.name}/>
                            </div>
                        )
                    })}
                </ColumnLayout>
            }
        </Container>
    )
}

export default BrokerAlarmsTable;
