import { CloudWatchWidget } from "./types";

export function widget(title: string, region: string, metrics: (string | object)[][], x = 0, y = 0, width = 24, height = 8, isExtendedPeriod?: boolean): CloudWatchWidget {
  const period = isExtendedPeriod ? 300 : 60;  // Set period to 300 if isExtendedPeriod is true, otherwise 60
  return {
      type: 'metric',
      x: x,
      y: y,
      width: width,
      height: height,
      properties: {
          region: region,
          title: title,
          period: period,
          view: "timeSeries",
          stacked: false,
          metrics: metrics,
          yAxis: {
            left: {
              min: 0
            },
            right: {
              min: 0
            }
          }
      },
  }
}
