import React, { useEffect, useState } from 'react';
import { Broker, EC2Instance, InternalException } from '@amzn/amazonmq-opsconsole-client';
import { Button, FormField, Header, Input, Popover, Select, SpaceBetween, Spinner, StatusIndicator, Table, TextContent } from '@amzn/awsui-components-react';
import { useNavigate, useParams } from 'react-router-dom';
import { BrokerInstance } from '../../../types';
import { JSONParser } from '@streamparser/json';
import { getRabbitQueue } from '../../../api/api';


type Props = {
    broker: Broker,
    brokerInstances: EC2Instance[] | undefined
}

type FieldValue = {
    field: string,
    value: any
}

type DescribeQueueOutput = {
    key: string,
    value: any
}

const DescribeQueue : React.FC<Props> = ({broker, brokerInstances}) => {

    const params = useParams();
    const [selectedInstance, setSelectedInstance] = useState<BrokerInstance | undefined>(undefined);
    const [warning, setWarning] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [queue, setQueue] = useState<DescribeQueueOutput[] | undefined>(undefined);
    const [error, setError] = useState<string>("");
    const [refreshCnt, setRefreshCnt] = useState<number>(0);
    const [vhostRaw, setVhostRaw] = useState<string>("/");
    const [queueNameRaw, setQueueNameRaw] = useState<string>("");
    const [vhostError, setVhostError] = useState<string>("");
    const [queueNameError, setQueueNameError] = useState<string>("");
    const navigate = useNavigate();

    let idx = params["*"] ? params["*"].lastIndexOf('/') : -1;

    const vhost = idx >= 0 ? params["*"]?.substring(0, idx) : "/";
    const queueName = idx >= 0 ? params["*"]?.substring(idx + 1) : "";

    let items: FieldValue[] = [];

    if (queue !== undefined) {
        queue.forEach(output => {
            items.push({field: output.key, value: output.value});
        });
    }

    let columnDefinitions = [
        {
            id: "field",
            header: "Field",
            sortingField: 'field',
            cell: (item: FieldValue)  => item.field
        },
        {
            id: "value",
            header: "Value",
            sortingField: 'value',
            cell: (item: FieldValue)  => JSON.stringify(item.value)
        },
    ];

    useEffect(() => {
        if (brokerInstances && brokerInstances.length > 0) {
            setSelectedInstance(brokerInstances[0]);
        }
    }, [brokerInstances])

    useEffect(() => {
        if (selectedInstance == undefined || !vhost || !queueName) {
            return;
        }
        setVhostRaw(vhost || "");
        setQueueNameRaw(queueName || "");
        setVhostError("");
        setQueueNameError("");
        setError("");
        setLoading(true);
        setWarning("");

        getRabbitQueue(broker.id, selectedInstance.instanceId, vhost, queueName).then(response => {
            if ((response as any).error) {
                setError((response.output as any).error)
            } else {
                
                let describeQueueOutput: DescribeQueueOutput[] = [];
                const duplicateKeys = new Set();
        
                const parser = new JSONParser({
                    keepStack: false,
                    paths: ["$.*"], // we only need first level root children
                });
                parser.onValue = (value) => {
                    if (value.key !== undefined) {
                        const hasDuplicate = describeQueueOutput.find(x => x.key == value.key);
                        if (hasDuplicate) {
                            duplicateKeys.add(value.key);
                        }
                        describeQueueOutput.push({key: value.key?.toString(), value: value.value});
                    }
                };
                parser.write(response.output);
        
                if (duplicateKeys.size > 0) {
                    setWarning("Duplicate keys: " + Array.from(duplicateKeys).join(' '))
                }

                setQueue(describeQueueOutput);

            }
        }).catch(error => {
            if (error instanceof InternalException && error.message) {
                setError(error.message);
            } else {
                console.error(error);
                setError("Could not describe queue");
            }
        }).finally(() => {
            setLoading(false);
        })
    }, [vhost, queueName, refreshCnt, selectedInstance?.instanceId])

    function onRefresh() {
        setRefreshCnt(refreshCnt + 1);
    }

    function validate() {
        setVhostError("");
        setQueueNameError("");
        let valid = true;
        if (!vhostRaw) {
            setVhostError("Enter vhost");
            valid = false;
        }
        if (!queueNameRaw) {
            setQueueNameError("Enter queue name");
            valid = false;
        }
        return valid;
    }

    function submit() {
        if (validate()) {
            navigate(`/broker/${broker.id}/queue/${encodeURIComponent(vhostRaw)}/${encodeURIComponent(queueNameRaw)}`);
            setRefreshCnt(refreshCnt + 1);
        }
    }

    function copyMarkdown() {
        if (queue === undefined) {
            return;
        }
        let markdown = `\n### Queue ${queueName} on vhost ${vhost}\n`;
        markdown += "---\n";
        markdown += "|Field|Value|\n"
        markdown += "|-|-|\n"
        queue.forEach(element => {
            markdown += "|" + element.key;
            markdown += "|" + "\"" + JSON.stringify(element.value) + "\"";
            markdown += "\n";
        })
        navigator.clipboard
            .writeText(markdown)
            .catch(() => {
                console.error("Could not copy markdown.");
            });
    }

    if (brokerInstances === undefined) {
        return <Spinner />
    }

    return (
        <SpaceBetween direction='vertical' size='xs'>
            <div style={{marginBottom: 8}}>
                <TextContent><p>Instance Id:</p></TextContent>
                <Select
                    options={[...brokerInstances.map(i => {
                        return {
                            value: i.instanceId,
                            id: i.instanceId,
                        }
                    })]}
                    selectedOption={{
                        value: selectedInstance?.instanceId,
                        label: selectedInstance?.instanceId,
                    }}
                    onChange={(event: any) => {
                        if (brokerInstances === undefined) {
                            return;
                        }
                        setSelectedInstance(brokerInstances.find(i => i.instanceId === event.detail.selectedOption.value))
                    }}
                />
            </div>
            <Table
                items={error ? [] : items}
                columnDefinitions={columnDefinitions}
                wrapLines={true}
                loading={loading}
                empty={(!vhost || !queueName) ? "Enter vhost and queue name" : ""}
                header={
                    <Header
                    
                        description={<SpaceBetween direction='vertical' size='xs'>
                            {warning && <StatusIndicator type='warning'>{warning}</StatusIndicator> }
                            {error && <StatusIndicator type='error'>{error}</StatusIndicator> }
                            </SpaceBetween>}
                        actions={
                            <SpaceBetween size='xs' direction='horizontal'>
                                <Popover
                                    dismissButton={false}
                                    position="top"
                                    size="small"
                                    triggerType="custom"
                                    content={
                                        <StatusIndicator type="success">
                                          Markdown copied
                                        </StatusIndicator>
                                    }
                                >
                                    <Button disabled={loading || !queue} onClick={() => {
                                        copyMarkdown();
                                    }}>Copy Markdown</Button>
                                </Popover>
                                <Button disabled={loading || !queue} iconName="refresh" onClick={() => {
                                    onRefresh();
                                }} />
                            </SpaceBetween>
                        } 
                    >
                        <SpaceBetween direction='horizontal' size='xs'>
                            <FormField label="Vhost" errorText={vhostError}>
                                <Input value={vhostRaw || ""} onKeyDown={(event) => {
                                    if (event.detail.key === 'Enter') {
                                        submit();
                                    }
                                }} onChange={(event) => {
                                    setVhostRaw(event.detail.value)
                                }}/>
                            </FormField>
                            <FormField label="Queue" errorText={queueNameError}>
                                <SpaceBetween direction='horizontal' size='xs'>
                                    <Input value={queueNameRaw || ""} onKeyDown={(event) => {
                                        if (event.detail.key === 'Enter') {
                                            submit();
                                        }
                                    }} onChange={(event) => {
                                        setQueueNameRaw(event.detail.value)
                                    }}/>
                                    <Button disabled={loading} variant='primary' onClick={() => {
                                        submit();
                                    }}>Describe</Button>
                                </SpaceBetween>
                            </FormField>
                        </SpaceBetween>
                    </Header>
                }
            />
        </SpaceBetween>
    )
}

export default DescribeQueue;