import React from 'react';
import { utcFormat } from 'd3-time-format';
import { ColumnLayout, Container, Header } from '@amzn/awsui-components-react/polaris';
import CopyToClipboardButton from "./copyToClipboardButton";
import { InstanceHistory } from '@amzn/amazonmq-opsconsole-client';

type Props = {
    instanceHistory: InstanceHistory[]
}

const InstanceHistoryTable : React.FC<Props> = ({instanceHistory}) => {

    return (
        <Container header={
            <Header variant='h2' description="Max 10 most recent instances are displayed">
                Instance History
            </Header>
        }>
            <div>
                <ColumnLayout columns={3} variant="text-grid">
                    {instanceHistory.map((history, idx) => {
                        return (
                            <div key={`instance-${idx}`}>
                                <div className='awsui-util-label'>
                                <span>{history.instanceId}</span>
                                <span><CopyToClipboardButton text={history.instanceId}/></span>
                                </div>
                                <span className="awsui-util-label">Created: {utcFormat('%B %d, %Y %H:%M')(new Date(history.createTime))}</span>
                            </div>
                        )
                    })}
                </ColumnLayout>
            </div>
        </Container>
    );

}

export default InstanceHistoryTable;
